import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  Modal,
  Text,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { TouchableOpacity } from "react-native";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { createCrossPlatformFormData, fileURLtoBlob } from "../../utils/encDec";
import { EditAPostApi } from "../../actions/auth-actions";
import CameraCustomView from "../../components/general/inputs/CameraCustomView";

export default function CreateGroupPostModal({
  Description,
  postImage,
  navigation,
  route,
  onRequestClose,
  visible,
  id,
  groupId,
  getGroupPosts,
  externalLinkURL,
  attachedFileURL,
}) {
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [showCameraView, setShowCameraView] = useState(false);
  const [activities, setActivities] = React.useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileObj, setSelectedFileObj] = useState(null);
  const [selectedImageObj, setSelectedImageObj] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [postDescription, setIsPostDescription] = useState();

  const [postExternalURL, setPostExternalURL] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });
  useEffect(() => {
    let img = postImage;
    let fileUrl = attachedFileURL;
    let desc = Description;
    let link = externalLinkURL;
    setSelectedImage(img);
    setSelectedFile(fileUrl);
    setIsPostDescription(desc);
    setPostExternalURL(link);
  }, []);
  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const openGallery = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
    });

    if (!result.canceled) {
      let imageObject = result?.assets[0];
      let path = imageObject?.uri?.replace("file:///", "file:/");
      // console.log(result.assets[0].uri, "result.assets[0].uri");

      setSelectedImage(result.assets[0].uri);
      // console.log(selectedImage, "selectedImage");
      if (result.assets[0].uri !== null) {
        fileURLtoBlob(path)
          .then((data) => {
            imageObject.blob = data;
            setSelectedImageObj(imageObject);
          })
          .catch((error) => {
            console.log("fileURLtoBlob-error", error);
          });
      }
    }
  };

  const openFilePicker = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    });
    if (result.type !== "cancel") {
      let path = result.uri.replace("file:///", "file:/");
      console.log(result.uri, "result.uri");
      setSelectedFileName(result.name);
      setSelectedFile(result.uri);
      // console.log(selectedFile, result, "selectedFile");
      fileURLtoBlob(path)
        .then((data) => {
          setSelectedFileObj({
            uri: result.uri,
            name: result.name,
            type: result.mimeType,
            blob: data,
          });
        })
        .catch((error) => {
          console.log("fileURLtoBlob-error", error);
        });
    }
  };

  const openCamera = async () => {
    setShowCameraView(true);
  };

  const EditPostForGroup = async () => {
    try {
      setIsLoading(true);
      const dataObj = {};
      if (postDescription) dataObj.description = postDescription;
      if (postExternalURL) dataObj.external_link_url = postExternalURL;
      if (selectedImageObj) dataObj.cover_image = selectedImageObj;
      if (selectedFileObj) dataObj.attached_file = selectedFileObj;

      const formData = createCrossPlatformFormData({
        blobDataKeys: ["cover_image", "attached_file"],
        data: dataObj,
      });
      if (Object.keys(dataObj)?.length) {
        const res = await EditAPostApi(groupId, id, formData);
        if (res.status == 200) {
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "Post Edited Successfully !!!",
          }));

          getGroupPosts(groupId);
          setTimeout(() => {
            setIsResponseModal((prev) => ({
              ...prev,
              visible: false,
            }));
            onRequestClose();
          }, 2000);
        } else {
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: res?.data?.message || "Post Edit Failed !!!",
          }));
        }
      }
    } catch (error) {
      console.log(
        "post Creation Failed",
        error,
        error?.response?.status,
        error?.response?.data
      );

      setIsLoading(false);
      setIsResponseModal((prev) => ({
        ...prev,
        visible: true,
        message: error?.response?.data?.message || "Post Creation Failed !!!",
      }));
    }
  };

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  return (
    <>
      {/* ------------------------Create Group-Tab----------------------------------- */}

      <Modal
        onRequestClose={onRequestClose}
        visible={visible}
        transparent={true}
        position="absolute"
      >
        {isLoading && <PleaseWaitModal showLoader={true} />}
        <ResponseModal
          visible={isResponseModal.visible}
          onRequestClose={closeResponseModal}
          bodyText={isResponseModal.message}
        ></ResponseModal>
        <TouchableWithoutFeedback onPress={onRequestClose}>
          <View className="flex-1 justify-center items-center bg-black/50">
            <View className=" p-2 w-4/5 md:w-[50%] bg-app-a border border-app-c min-h-[160px] max-h-full rounded-lg justify-center items-center -mt-12">
              <View className="w-full flex-row justify-end items-center">
                <Pressable
                  onPress={() =>
                    postDescription == "" ? null : EditPostForGroup()
                  }
                  className="mr-1 px-2 py-1 flex-center items-center bg-green-100 border border-green-600 rounded-lg"
                >
                  <Font
                    className="text-sm text-green-600"
                    // onPress={() => setOverlayVisible(true)}
                  >
                    Post
                  </Font>
                </Pressable>
              </View>
              <View className="w-full py-4 px-1 flex-row">
                <View className="mr-2.5">
                  <Image
                    source={require("../../../assets/user.jpg")}
                    className="w-8 h-8 rounded-full"
                  />
                </View>
                <View className="flex-col flex-1">
                  <TouchableOpacity className="w-full">
                    <TextInput
                      className="w-full p-2 border border-gray-300 rounded-xl"
                      placeholder="Write something..."
                      placeholderTextColor="#94a3b8"
                      multiline={true}
                      numberOfLines={4}
                      value={postDescription}
                      onChangeText={(e) => setIsPostDescription(e)}
                    />
                  </TouchableOpacity>
                  <View className="border border-gray-300 rounded-xl p-2 w-full mt-2">
                    <TextInput
                      className="w-full"
                      placeholder="https link to external url"
                      placeholderTextColor="#94a3b8"
                      value={postExternalURL}
                      onChangeText={(e) => setPostExternalURL(e)}
                    />
                  </View>
                </View>
              </View>
              {selectedFile != "" && selectedFile != null && (
                <View className="w-full  h-16 mb-5 bg-gray-200 rounded-md">
                  <Pressable
                    onPress={() => {
                      setSelectedFile(null);
                      setSelectedFileObj(null);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                    className={`w-5 h-5 rounded-full justify-center items-center`}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
                  </Pressable>
                  <View className="flex-col h-full items-center justify-center">
                    <RemixIcon
                      name="ri-file-text-fill"
                      size={18}
                      color={colors.app.a4}
                      className=" w-5 h-5 lg:w-8  flex-shrink-0 mb-2"
                    />
                    <Text
                      className="flex items-center justify-center"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      {selectedFileName ?? "Attached File"}
                    </Text>
                  </View>
                  {/* <Image
                    source={{ uri: selectedImage }}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  /> */}
                </View>
              )}
              {selectedImage != "" && selectedImage != null && (
                <View className="w-full h-56 md:h-44 justify-center items-center ">
                  {selectedImage !== postImage ? (
                    <Pressable
                      onPress={() => {
                        setSelectedImage(postImage);
                        setSelectedImageObj(null);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        zIndex: 9999,
                        backgroundColor: "gray",
                      }}
                      className={`w-5 h-5 rounded-full justify-center items-center`}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        X
                      </Text>
                    </Pressable>
                  ) : (
                    <></>
                  )}
                  {/* <View className=" items-center "> */}
                  <Image
                    source={{ uri: selectedImage }}
                    className="w-full  h-full  md:w-[15%]  rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  />
                  {/* </View> */}
                </View>
              )}
            </View>
            <View className="p-2 w-full flex-row justify-center item-center ">
              <Pressable
                onPress={openFilePicker}
                className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
              >
                <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                  <View className="flex justify-center items-center">
                    <RemixIcon
                      name="ri-file-text-fill"
                      size={16}
                      color={colors.app.a4}
                      className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                    />
                  </View>
                  <Font className="lg:ml-4 text-sm">File</Font>
                </View>
              </Pressable>
              <Pressable
                onPress={openGallery}
                className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
              >
                <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                  <View className="mr-2 lg:mr-4 flex justify-center items-center">
                    <RemixIcon
                      name="ri-image-fill"
                      size={16}
                      color={colors.app.b4}
                      className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                    />
                  </View>
                  <Font className="lg:ml-4 text-sm">Gallery</Font>
                </View>
              </Pressable>
              <Pressable
                onPress={openCamera}
                className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
              >
                <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                  <View className="mr-2 lg:mr-4 flex justify-center items-center">
                    <RemixIcon
                      name="ri-camera-fill"
                      size={16}
                      color={colors.app.c4}
                      className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                    />
                  </View>
                  <Font className="lg:ml-4 text-sm">Camera</Font>
                </View>
              </Pressable>
            </View>
            {overlayVisible && (
              <TouchableOpacity
                className="absolute inset-0 justify-center items-center "
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  backdropFilter: "blur(10px)",
                }}
                activeOpacity={1}
                onPressOut={() => setOverlayVisible(false)}
              >
                <TouchableOpacity
                  className="m-5 bg-white rounded-lg p-8 items-center shadow-lg"
                  activeOpacity={1}
                  onPress={() => {}}
                >
                  {/* <Button title="Show Overlay" /> */}
                  <View className="p-2 w-full flex-row item-center bg-app-a">
                    <View className="mr-2 -ml-8">
                      <Image
                        source={require("../../../assets/user.jpg")}
                        className="w-10 h-10 rounded-full"
                      />
                    </View>

                    <View className="flex-1 w-full">
                      <View className="w-full flex-row justify-between items-center">
                        <Font className="text-wrap mr-2 mb-2">
                          Description lorem ipsum dolor sit amet bla slo are
                          asli whey lorem dolor siu
                        </Font>
                      </View>
                      <View className="w-full h-56">
                        <Image
                          source={require("../../../assets/wallPost.jpg")}
                          className="w-full h-full rounded-lg"
                          // style={{ resizeMode: 'cover',}}
                        />
                      </View>
                      <View className="mt-6 flex-row justify-center items-center">
                        <View className="px-12 py-1  flex justify-center items-center rounded-lg bg-red-200 border-2 border-app-e mr-8 lg:mr-12">
                          <Pressable onPress={() => setOverlayVisible(false)}>
                            <Font className="text-app-e">Post</Font>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              </TouchableOpacity>
            )}
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      {showCameraView ? (
        <CameraCustomView
          onImageCapture={(result) => {
            let path =
              Platform.OS === "ios"
                ? result?.uri?.replace("file:///", "file:/")
                : result?.uri;
            setSelectedImage(result.uri);
            if (result.uri !== null) {
              fileURLtoBlob(path)
                .then((data) => {
                  result.blob = data;
                  setSelectedImageObj(result);
                })
                .catch((error) => {
                  console.log("fileURLtoBlob-error", error);
                });
            }
            setShowCameraView(false);
            setSelectedImage(result?.uri);
            setSelectedImageObj(result);
          }}
          onRequestClose={() => {
            setShowCameraView(false);
          }}
          onDismiss={() => {
            setShowCameraView(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
