import React from "react";
import { Platform } from "react-native";

const SVGLoader = ({ src, width, webWidth, height, webHeight, fill }) => {
  if (Platform.OS === "web") {
    let styles = {};
    if (webWidth !== undefined) styles.width = `${webWidth}`;
    else if (width !== undefined) styles.width = `${width}px`;
    if (webHeight !== undefined) styles.height = `${webHeight}`;
    else if (height !== undefined) styles.height = `${height}px`;
    if (fill !== undefined) styles.color = `${fill}`;
    return (
      <object data={src} style={styles} onClick={(e) => e.stopPropagation()}>
        <img src={src} style={styles} />
      </object>
    );
  }
  const Src = src,
    props = {};
  if (width !== undefined) props.width = width;
  if (height !== undefined) props.height = height;
  if (fill !== undefined) props.fill = fill;
  return <Src {...props} />;
};

export default SVGLoader;
