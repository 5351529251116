import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  Text,
  TouchableWithoutFeedback,
  Modal,
  Share,
  FlatList,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";

import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import * as Linking from "expo-linking";
import SVGLoader from "../../utils/SVGLoader";
import verticalmenu from "../../../assets/white-menu-vertical.svg";
import edit from "../../../assets/pencil.svg";
import profilelock from "../../../assets/Lock.svg";
import horizontalMenu from "../../../assets/horizontal-menu.svg";
import CreateGroupPost from "./create-group-post";
import GroupMembers from "./group-members";
import GroupSettings from "./groups-settings";
import {
  deleteAPost,
  getAPost,
  getGroupMembers,
  LikePostApi,
  ShareAPost,
} from "../../actions/auth-actions";
import { useAuthContext } from "../../contexts/auth-context";
import EditModal from "../../components/general/modals/EditModal";
import moment from "moment";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import CommentSection from "../../components/general/commentSection";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import CreateGroupPostModal from "./create-post-modal";

import GroupPosts from "../../components/groupPosts";

export default function ManageGroups({
  key,
  navigation,
  route,
  Id,
  Name,
  Description,
  IsPrivate,
  GroupStatus,
  checkRefreshList = () => null,
  closeGroup,
}) {
  const [isRefreshList, setRefreshList] = React.useState(true);
  const checkRefreshListforPosts = () => {
    setRefreshList(!isRefreshList);
  };

  useEffect(() => {
    if (route?.params) {
      const {
        id,
        name,
        description,
        isPrivate,
        groupStatus,
        checkRefreshList,
      } = route?.params;
      setId(id);
      setName(name);
      setDescription(description);
      setIsPrivate(isPrivate);
      setGroupStatus(groupStatus);
      // setCheckRefreshList(checkRefreshList);
      checkRefreshList(checkRefreshList);
      getGroupPosts(id, currentPage);
      GetMembers(id);
    } else {
      setId(Id);
      setName(Name);
      setDescription(Description);
      setIsPrivate(IsPrivate);
      setGroupStatus(GroupStatus);
      // setCheckRefreshList(checkRefreshList);
      checkRefreshList(checkRefreshList);
      getGroupPosts(Id, currentPage);
      GetMembers(Id);
    }
  }, [isRefreshList, route?.params]);

  // const { id, name, description, isPrivate, groupStatus, checkRefreshList } =
  // //   route?.params;
  const [id, setId] = React.useState();
  const [name, setName] = React.useState();
  const [description, setDescription] = React.useState();
  const [isPrivate, setIsPrivate] = React.useState();
  const [groupStatus, setGroupStatus] = React.useState();
  //   const checkRefreshList  = (func) =>{
  // func();
  //   }
  // const [checkRefreshList,setCheckRefreshList ] = React.useState();

  // const { id, name, description, isPrivate, groupStatus, checkRefreshList } =
  //   route.params;
  const { user } = useAuthContext();
  // console.log(id, " group id check");
  // console.log(user.id, "id check");
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("home");
  const [refreshing, setRefreshing] = React.useState(false);
  const [isActiveTab, setIsActiveTab] = useState("Public");
  const [isEditModal, setIsEditModal] = useState(false);

  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const [isPostId, setIsPostId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setIsLoading] = React.useState(false);
  const [getPostsLoading, setGetPostsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const closeEditModal = () => setIsEditModal(false);
  const [MembersList, setMembersList] = useState([]);
  const [groupPost, setGroupPost] = useState([]);

  const scrollViewRef = React.useRef(null);

  const onRefresh = async () => {
    setRefreshing(true);
    setGroupPost([]);
    await getAPost();
    setRefreshing(false);
  };

  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();
  // React.useEffect(() => {
  //   getGroupMembers(id);
  // }, []);
  const GetMembers = async (id) => {
    try {
      let res = await getGroupMembers(id);
      if (res.status === 200) {
        setMembersList(res.data.data);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };
  const getGroupPosts = async (id, page = 1) => {
    try {
      if (page === 1) {
        setGetPostsLoading(true);
        setIsLoading(true);
      } else {
        setIsFetchingMore(true);
      }
      let res = await getAPost(id, page);
      if (res.status === 200) {
        setGetPostsLoading(false);
        setIsFetchingMore(false);
        setIsLoading(false);
        setGroupPost((prevData) =>
          page === 1 ? res.data.posts : [...prevData, ...res.data.posts]
        );
      } else {
        setGetPostsLoading(false);
      }
    } catch (error) {
      setGetPostsLoading(false);
      setIsFetchingMore(false);
      setIsLoading(false);
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const loadMoreData = () => {
    console.log("on end reached ?", loading, isFetchingMore);

    // if (!isFetchingMore && !loading) {
    //   setCurrentPage((prevPage) => prevPage + 1);
    // }
  };

  const buttonRef = React.useRef(null);
  const [buttonPosition, setButtonPosition] = React.useState({});
  // console.log("navigation-manage grp:", navigation);
  useEffect(() => {
    getActivities();
    // getGroupPosts(currentPage);
  }, [isRefreshList, currentPage]);

  const renderItem = ({ item, i }) => {
    return (
      <View
        key={i}
        className="p-2 w-full flex justify-center items-center lg:justify-start lg:items-start bg-app-a border-t border-t-gray-300"
      >
        <View className="w-full flex-row justify-between items-center ">
          <View className="flex-row justify-start items-center">
            <View className="mr-2">
              <Image
                source={require("../../../assets/user.jpg")}
                className="w-10 h-10 rounded-full"
              />
            </View>
            <View className="mt-2 flex justify-start item-center">
              <Font className="text-sm text-app-d font-bold">{name}</Font>
              <View className="flex-row justify-start items-center">
                {/* <Font className="text-xs text-gray-500">
                                Owner
                              </Font> */}
                {/* <Font className="mr-2 ml-2 flex items-center text-xs font-extrabold self-center">
                                .
                                </Font> */}
                <Font className="text-xs text-gray-500">
                  {timeAgo(item?.created_at)}
                </Font>
              </View>
            </View>
          </View>
          <Pressable
            className="mr-2 w-4 h-4 lg:w-10 lg:h-10"
            onPress={() => {
              console.log("pressed !");

              setIsPostId(item?.id);
              openMenu();
            }}
            // ref={buttonRef}
          >
            {/* <View> */}
            {/* <SVGLoader
                src={horizontalMenu}
                height={25}
                width={20}
                webHeight={"20em"}
                webWidth={"auto"}
              /> */}

            <RemixIcon name="ri-more-fill" color={"#000000"} size="18" />
            {/* </View> */}
          </Pressable>
        </View>
        {item?.description ? (
          <View className="py-2 w-full text-sm">
            <Font>
              {/* Lorem ipsum dolor sit amet. Ea velit reprehenderit est
                          magni sequi id similique libero sit ipsam cumque... */}
              {item?.description}
            </Font>
          </View>
        ) : (
          <></>
        )}
        {item?.cover_image_url !== null && (
          <View className="w-full h-56 md:h-96 justify-center items-center ">
            {/* <View className=" items-center "> */}
            <Image
              resizeMode="cover"
              source={{
                uri:
                  "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  item?.cover_image_url,
              }}
              className="w-auto md:w-[30%] h-full rounded-lg justify-center items-center"
              // style={{ resizeMode: "contain" }}
            />
            {/* </View> */}
          </View>
        )}
        {item?.external_link_url ? (
          <Pressable
            className="p-2 mb-0 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.external_link_url
                ? Linking.openURL(
                    `${item?.external_link_url}`.replace(
                      /^(?!https?:\/\/)/,
                      "https://"
                    )
                  ).catch((e) => console.log("External URL error:", e))
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {item?.external_link_url}
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click on this link to visit
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Learn More</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        {item?.attached_file_url ? (
          <Pressable
            className="p-2 mb-5 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.attached_file_url
                ? Linking.openURL(
                    "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                      item?.attached_file_url
                  )
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {/* {item?.external_link_url} */}
                  Attached File
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click to Download file
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Download</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        <View className="p-4 w-full flex-row justify-between item-center border-b border-t border-b-gray-300">
          <View className="flex-row justify-center item-center">
            <View className="z-10 h-8 w-8 bg-indigo-600 border-4 border-gray-300 rounded-full items-center justify-center">
              <RemixIcon
                name="ri-thumb-up-fill"
                color={colors.app.a}
                size="18"
              />
            </View>
            <View className="-ml-2 h-8 w-8 bg-red-400 border-4 border-gray-300 rounded-full items-center justify-center">
              <RemixIcon name="ri-heart-fill" color={colors.app.a} size="18" />
            </View>
            <View className="flex justify-center items-center">
              <Font className="text-base text-gray-500">{item?.likes}</Font>
            </View>
          </View>
          <View className="w-full flex-row justify-center item-center">
            <Font className="text-base text-gray-500 mr-2">
              {item?.comments} Comments
            </Font>
            <Font className="text-base text-gray-500">
              {item?.shares} Shares
            </Font>
          </View>
        </View>
        <View className="py-2 w-full flex-row justify-evenly item-center">
          <>
            <View className="w-full flex-row justify-around item-center">
              <Pressable
                onPress={() => LikeDisLikePost(item?.id, item?.is_liked, i)}
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-thumb-up-line"
                    color={item?.is_liked ? colors.app.f : colors.app.d1}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Like</Font>
              </Pressable>
              <Pressable
                onPress={() => {
                  setIsPostId(item?.id);
                  setIsCommentSectionVisible((prev) => !prev);
                }}
                // onPress={() =>
                //   navigation.navigate("vendor-feed-comments", {
                //     slug: id,
                //   })
                // navigation.goBack()
                // }
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-chat-4-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Comment</Font>
              </Pressable>
              <Pressable
                onPress={() => sharePostApi(item?.id, i)}
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-share-forward-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Share</Font>
              </Pressable>
              {isPostId == item?.id ? (
                <CreateGroupPostModal
                  visible={isEditPostModal}
                  onRequestClose={closeEditPostModal}
                  postImage={
                    item?.cover_image_url
                      ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                        item?.cover_image_url
                      : null
                  }
                  Description={item?.description}
                  attachedFileURL={item?.attached_file_url}
                  externalLinkURL={item?.external_link_url}
                  id={item?.id}
                  groupId={id}
                  getGroupPosts={getGroupPosts}
                  // selectedImgObj={selectedImageObj}
                />
              ) : (
                <></>
              )}
            </View>
            {isCommentSectionVisible && isPostId == item?.id ? (
              <Modal
                visible={isCommentSectionVisible}
                transparent={true}
                onRequestClose={closeComment}
              >
                <TouchableWithoutFeedback onPress={closeComment}>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="w-full h-full justify-center items-center bg-black/50"
                  >
                    <CommentSection
                      // route={{ params: { slug: id } }}
                      id={item?.id}
                      groupId={id}
                      useId={true}
                      navigation={navigation}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </Modal>
            ) : (
              <></>
            )}
          </>
        </View>
      </View>
    );
  };
  // let MembersLength = MembersList?.length;
  // let MembersLength =2
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {/* --------------------------mobile-screen Group Navbar--------------------------- */}

      <View className="p-4 pt-2 w-full flex lg:hidden bg-app-e">
        <View className="w-full flex-col">
          {/* <View className="w-3 h-3">
              <SVGLoader
              src={leftArrow}
              height={40}
              width={40}
                webHeight={"20em"}
                webWidth={"auto"}
                />
            </View> */}
          <View className="flex-row justify-end items-center">
            {/* <View className="w-5 h-5">
                <SVGLoader
                  src={bell}
                  height={40}
                  width={40}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </View> */}
            <View className="w-5 h-5 mb-2 mr-2">
              {/* <SVGLoader
                src={verticalmenu}
                height={30}
                width={25}
                webHeight={"20em"}
                webWidth={"auto"}
              /> */}
            </View>
          </View>
          <EditModal
            navigation={navigation}
            visible={isEditModal}
            onRequestClose={closeEditModal}
            userId={user.id}
            groupId={id}
            name={name}
            description={description}
            activetab={isPrivate == true ? "Private" : "Public"}
            checkRefreshList={checkRefreshList}
          ></EditModal>
          <View className="w-full justify-center items-center">
            <View
              className="mb-2 flex-col rounded-lg"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <View className="px-6 py-1 flex-col justify-center items-center">
                <Font className="text-lg text-app-a font-bold">
                  {/* Relax Baby sitting */}
                  {name}
                </Font>
                <Font className="text-sm text-app-a">{description}</Font>
                {/* <Font className="text-sm text-app-a">just relax</Font> */}
              </View>
              <View className="flex-row justify-end">
                <Pressable
                  onPress={() => setIsEditModal(true)}
                  className="px-2 flex-row justify-end items-center rounded-full"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                >
                  <Font className="text-xs text-app-a mr-2">Edit</Font>
                  <View className="w-3 h-3 flex justify-center items-center">
                    {/* <SVGLoader
                      src={edit}
                      height={10}
                      width={10}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    /> */}
                    <RemixIcon
                      name="ri-pencil-fill"
                      size={16}
                      color={"#ffffff"}
                      // className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                    />
                  </View>
                </Pressable>
              </View>
            </View>
            <View className="flex-row justify-center item-center">
              <View className="mr-2 w-3 h-3 flex justify-center item-center">
                {/* <SVGLoader
                  src={profilelock}
                  height={20}
                  width={20}
                  webHeight={"20em"}
                  webWidth={"auto"}
                /> */}
                <RemixIcon
                  className=" mt-1 "
                  name={isPrivate == true ? `ri-lock-fill` : `ri-earth-fill`}
                  size={12} // or use Tailwind text-size class
                  color="#ffffff"
                />
              </View>
              <Font className="text-xs text-app-a">
                {isPrivate == true ? "Private" : "Public"} group .{" "}
                {MembersList?.length} members
              </Font>
            </View>
          </View>
        </View>
      </View>

      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className="max-w-screen-lg w-full h-full">
          {/* ------------------------------------------------lg-screen header--------------------------------------------- */}
          <View className="max-w-screen-lg w-full flex-col justify-center items-center">
            <View className="p-4 pt-2 w-full hidden lg:flex bg-app-e rounded-lg">
              <View className="w-full flex-col">
                <View className="w-full flex-row justify-end items-center">
                  <View className="w-5 h-5 mb-2">
                    <SVGLoader
                      src={verticalmenu}
                      height={40}
                      width={40}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </View>
                </View>
                <View className="w-full justify-center items-center">
                  <View
                    className="mb-2 flex-col rounded-lg"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  >
                    <View className="px-10 py-1 flex-col justify-center items-center">
                      <Font className="text-lg text-app-a font-bold">
                        {/* Relax Baby sitting */}
                        {name}
                      </Font>
                      <Font className="text-sm text-app-a">{description}</Font>
                      {/* <Font className="text-sm text-app-a">just relax</Font> */}
                    </View>
                    <View className="flex-row justify-end">
                      <Pressable
                        onPress={() => setIsEditModal(true)}
                        className="px-2 flex-row justify-end items-center rounded-full"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      >
                        <Font className="text-xs text-app-a mr-2">Edit</Font>
                        <View className="w-3 h-3">
                          {/* <SVGLoader
                            src={edit}
                            height={40}
                            width={40}
                            webHeight={"20em"}
                            webWidth={"auto"}
                          /> */}
                          <RemixIcon
                            name="ri-pencil-fill"
                            size={16}
                            color={"#ffffff"}
                            // className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                      </Pressable>
                    </View>
                  </View>
                  <View className="flex-row justify-center item-center">
                    <View className="mr-2 w-3 h-3 flex justify-center item-center">
                      <SVGLoader
                        src={profilelock}
                        height={40}
                        width={40}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                    <Font className="text-xs text-app-a">
                      {isPrivate == true ? "Private" : "Public"} group .{" "}
                      {MembersList?.length} members
                    </Font>
                  </View>
                </View>
              </View>
            </View>

            {/* ------------------------Group-Tabs----------------------------------- */}
            <View className="w-full flex-col rounded-lg">
              <View className="py-4 px-4 w-full flex-row justify-center overflow-auto">
                <Pressable
                  className={`py-2 px-2 m-1 mr-0.5 lg:m-2 min-w-max  border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "home" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("home");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "home"
                        ? "text-app-a"
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Home
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "create-post"
                      ? "bg-app-e"
                      : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("create-post");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "create-post"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Create a Post
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "members" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("members");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "members"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Members
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-2 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "settings" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("settings");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "settings"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Settings
                  </Font>
                </Pressable>
              </View>

              {/* ------------------------------Home Tab Section------------------------------ */}

              {isGroupHandlingTab === "home" && (
                <>
                  {/* <View className="p-4 w-full flex-row item-center bg-app-a">
                    <View className="p-2 w-full flex-row item-center">
                      <View className="-ml-4 mr-1 lg:mr-2">
                      <Image
                      source={require("../../../assets/user.jpg")}
                      className="w-8 h-8 lg:w-10 lg:h-10 rounded-full"
                      />
                      </View>
                      <TextInput
                        className="p-1 -mr-1 w-full border border-gray-300 rounded-2xl"
                        placeholder="Write something..."
                        placeholderTextColor="#94a3b8"
                        numberOfLines={4}
                        />
                    </View>
                    </View> */}

                  {/* <View className="p-2 w-full flex-row justify-center item-center border-t border-t-gray-300">
                    <Pressable className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                    <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="flex justify-center items-center">
                    <RemixIcon
                            name="ri-file-text-fill"
                            size={16}
                            color={colors.app.a4}
                            className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                        <Font className="lg:ml-4 text-sm">File</Font>
                      </View>
                    </Pressable>
                    <Pressable className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                      <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                      <View className="mr-2 lg:mr-4 flex justify-center items-center">
                          <RemixIcon
                          name="ri-image-fill"
                            size={16}
                            color={colors.app.b4}
                            className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                            />
                        </View>
                        <Font className="lg:ml-4 text-sm">Gallery</Font>
                        </View>
                    </Pressable>
                    <Pressable className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                      <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                        <View className="mr-2 lg:mr-4 flex justify-center items-center">
                        <RemixIcon
                        name="ri-camera-fill"
                        size={16}
                        color={colors.app.c4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                        </View>
                        <Font className="lg:ml-4 text-sm">Camera</Font>
                        </View>
                    </Pressable>
                  </View> */}

                  {getPostsLoading ? (
                    <ActivityIndicator
                      animating={getPostsLoading}
                      className="self-center justify-self-center flex-1 w-full h-full"
                      color={colors.app.e}
                      size="large"
                    />
                  ) : groupPost?.length == 0 ? (
                    <View className=" w-full flex justify-center items-center">
                      <Font
                        className="text-app-d1 text-xs items-center"
                        weight={500}
                      >
                        No Post
                      </Font>
                    </View>
                  ) : (
                    // groupPost?.map((e, i) => (

                    // ))
                    // <></>
                    groupPost?.map((e, i) => {
                      return (
                        <GroupPosts
                          key={i}
                          i={i}
                          item={e}
                          name={name}
                          groupId={id}
                          getGroupPosts={getGroupPosts}
                          setGroupPost={setGroupPost}
                          groupPost={groupPost}
                        />
                      );
                    })
                    // <FlatList
                    //   ref={scrollViewRef}
                    //   data={groupPost}
                    //   renderItem={renderItem}
                    //   keyExtractor={(item) => item.id.toString()}
                    //   // contentContainerStyle={styles.flatListContent}
                    //   refreshControl={
                    //     <RefreshControl
                    //       refreshing={refreshing}
                    //       onRefresh={onRefresh}
                    //     />
                    //   }
                    //   onEndReached={loadMoreData}
                    //   onEndReachedThreshold={0.5}
                    //   ListFooterComponent={() => {
                    //     console.log(isFetchingMore, "isFetchingMore");
                    //     // return (
                    //     //   <ActivityIndicator
                    //     //     animating={isFetchingMore}
                    //     //     className="self-center justify-self-center flex-1 w-full h-full"
                    //     //     color={colors.app.e}
                    //     //     size="small"
                    //     //   />
                    //     // );
                    //   }}
                    //   // onScroll={handleScroll}
                    // />
                  )}
                </>
              )}
              {/* ---------------------Create Post tab Section -------------------------- */}
              {isGroupHandlingTab === "create-post" && (
                <CreateGroupPost
                  navigation={navigation}
                  groupId={id}
                  checkRefreshListforPosts={checkRefreshListforPosts}
                />
              )}

              {/* -----------------------Group-members Tab Section----------------------------- */}
              {isGroupHandlingTab === "members" && (
                <GroupMembers
                  navigation={navigation}
                  MembersLists={MembersList}
                  id={user.id}
                  groupId={id}
                />
              )}

              {/* -----------------------Group settings tab section------------------------------ */}
              {isGroupHandlingTab === "settings" && (
                <GroupSettings
                  navigation={navigation}
                  groupId={id}
                  checkRefreshList={checkRefreshListforPosts}
                  userId={user.id}
                  isPrivate={isPrivate}
                  groupStatus={groupStatus}
                />
              )}
            </View>
          </View>
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </View>
  );
}
