import React, { useState } from "react";
import { Image, Pressable, TextInput, TouchableOpacity } from "react-native";

import { View } from "react-native";
import { Modal } from "react-native";
import { Font } from "../../font";
import SVGLoader from "../../../utils/SVGLoader";
import { Card } from "../cards/card";
import CustomTooltip from "../tooltips/floatingToolTip";
import { Button } from "../../input";
import { EditGroup } from "../../../actions/auth-actions";
import ResponseModal from "./ResponseModal";

const EditModal = ({
  navigation,
  userId,
  groupId,
  name,
  description,
  activetab,
  visible,
  onRequestClose,
  animationType = "fade",
  bodyText,
  dismissText,
  checkRefreshList,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [group, setGroup] = useState({
    name: "",
    description: "",
  });
  React.useEffect(() => {
    setGroup((prevGroup) => ({
      ...prevGroup, // Keep other properties of the state if there are any
      name: name || "", // default to an empty string if name is undefined
      description: description || "", // default to an empty string if description is undefined
    }));
  }, [name, description]);
  const [isActiveTab, setIsActiveTab] = useState(activetab);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });
  const changeCredentials = (field, value) => {
    setGroup((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const editGroup = async () => {
    try {
      setIsEditing(true);
      const formData = new FormData();
      formData.append("name", group.name);
      formData.append("description", group.description);
      formData.append("is_private", isActiveTab == "Public" ? false : true);

      const res = await EditGroup(userId, groupId, formData);
      if ((res.status = 200)) {
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Edited Successfully !!!",
        }));
        setTimeout(() => {
          checkRefreshList();
        }, 2000);
        setTimeout(() => {
          navigation.goBack();
        }, 2000);
        console.log("success", res);
      } else {
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Something went wrong !!!",
        }));
        setTimeout(() => {
          navigation.goBack();
        }, 2000);
        checkRefreshList();
        console.log("failed", res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsEditing(false);
    }
  };
  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };

  return (
    <Modal
      animationType={animationType}
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>
      <View className="w-full h-full justify-center items-center  bg-black/50">
        <View className="w-[80%] md:w-[60%] p-0 pt-0 justify-center items-center bg-white rounded-lg">
          {/* <Card className="flex-col lg:flex-row mb-5"> */}
          <View className="flex-col lg:flex-row w-full m-4 mb-0 mt-0 p-4">
            <View className="flex-col w-full lg:w-1/2">
              <View className="my-3 hidden lg:flex lg:pl-3">
                {/* <Font className="text-sm font-semibold text-app-d mb-2">
                  Groups
                </Font> */}
                <View className="flex-row  lg:mb-2">
                  <Font className="text-sm font-semibold text-app-d ">
                    Name
                  </Font>
                  {/* <Font className="text-sm text-gray-200 ml-2">(Optional)</Font> */}
                </View>
                <View className="border-t-2 border-t-gray-200 w-full" />
              </View>
              <View className="w-full lg:pl-3">
                <TextInput
                  className="w-full lg:w-11/12 border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                  placeholder="Group name"
                  placeholderTextColor="#94a3b8"
                  type="text"
                  value={group.name}
                  onChangeText={(value) => changeCredentials("name", value)}
                />
              </View>
            </View>
            <View className="flex-col w-full lg:w-1/2">
              <View className="my-3 hidden lg:flex lg:pr-3">
                <View className="flex-row  lg:mb-2">
                  <Font className="text-sm font-semibold text-app-d ">
                    Description
                  </Font>
                  {/* <Font className="text-sm text-gray-200 ml-2">(Optional)</Font> */}
                </View>
                <View className="border-t-2 border-t-gray-200 w-full" />
              </View>
              <View className="w-full lg:pr-3">
                <TextInput
                  className="w-full lg:w-11/12 border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                  placeholder="Group Description"
                  placeholderTextColor="#94a3b8"
                  type="text"
                  value={group.description}
                  onChangeText={(value) =>
                    changeCredentials("description", value)
                  }
                />
              </View>
            </View>
          </View>
          {/* </Card> */}

          {/* <Card className="hidden lg:flex w-full">

            <View className="flex-row justify-center mt-0 mb-20">
              <CustomTooltip
                align="bottom-right"
                tooltipText="Viewed and access by all register users."
                onPress={() => {
                  setIsActiveTab("Public");
                }}
              >
                <View
                  className={`min-w-max shadow-sm  border rounded-tl-lg rounded-bl-lg px-8 py-2 pb-4 ${
                    isActiveTab === "Public"
                      ? "bg-red-200 border-app-e"
                      : "border-gray-300 "
                  } `}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isActiveTab === "Public" ? "text-app-e" : "text-gray-300"
                    }`}
                    weight={600}
                  >
                    Public
                  </Font>
                </View>
              </CustomTooltip>
              <View className="p-2" />
              <CustomTooltip
                align="bottom-left"
                tooltipText="Viewed and access only by group members."
                onPress={() => {
                  setIsActiveTab("Private");
                }}
              >
                <View
                  className={`min-w-max shadow-sm  rounded-tr-lg rounded-br-lg px-8 py-2  pb-4 border ${
                    isActiveTab === "Private"
                      ? "border-app-e bg-red-200"
                      : "border-gray-300"
                  }`}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isActiveTab === "Private" ? "text-app-e" : "text-gray-300"
                    }`}
                    weight={600}
                  >
                    Private
                  </Font>
                </View>
              </CustomTooltip>
            </View>
          </Card> */}

          <View className="block flex-row justify-center mt-10 mb-20">
            <CustomTooltip
              align="bottom-right"
              tooltipText="Viewed and access by all register users."
              onPress={() => {
                setIsActiveTab("Public");
              }}
            >
              <View
                className={`min-w-max shadow-sm  border rounded-tl-lg rounded-bl-lg px-8 py-2 ${
                  isActiveTab === "Public"
                    ? "bg-red-200 border-app-e"
                    : "border-gray-300 "
                } `}
              >
                <Font
                  className={`text font-medium self-center justify-self-center ${
                    isActiveTab === "Public" ? "text-app-e" : "text-gray-300"
                  }`}
                  weight={600}
                >
                  Public
                </Font>
              </View>
            </CustomTooltip>
            <CustomTooltip
              align="bottom-left"
              tooltipText="Viewed and access only by group members."
              onPress={() => {
                setIsActiveTab("Private");
              }}
            >
              <View
                className={`min-w-max shadow-sm  rounded-tr-lg rounded-br-lg px-8 py-2  border  ${
                  isActiveTab === "Private"
                    ? "border-app-e bg-red-200"
                    : "border-gray-300"
                }`}
              >
                <Font
                  className={`text font-medium self-center justify-self-center ${
                    isActiveTab === "Private" ? "text-app-e" : "text-gray-300"
                  }`}
                  weight={600}
                >
                  Private
                </Font>
              </View>
            </CustomTooltip>
          </View>

          <View className="mb-12 flex-row lg:flex-row w-full justify-center lg:justify-center">
            <Button
              label="cancel"
              type="regular-outline"
              className="min-w-max px-6 mx-1"
              // style={{ flex: 1 }}
              onPress={() => onRequestClose()}
            />

            <Button
              label="Edit"
              type="primary"
              className="min-w-max px-6 mx-1"
              // style={{ flex: 1 }}
              onPress={() => editGroup()}
              waitState={isEditing}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default EditModal;
