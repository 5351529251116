import React, { useContext, useEffect, useState } from "react";
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { TouchableOpacity } from "react-native";
import * as Contacts from "expo-contacts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";
import { RefreshControl } from "react-native";

export const InviteContact = ({ navigation, route }) => {
  const { height } = useWindowDimensions();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [pagination, setPagination] = useState({
    total: 0,
    offset: 0,
    limit: 50,
  });

  // const [inValue ,setInValue] = useState(contact.selected)

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    fetchInvitedContacts();
  }, []);

  const fetchContacts = async ({ offset = 0, limit = height / 110 } = {}) => {
    console.log("from:", offset + 1, "to:", offset + limit);
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === "granted") {
      setIsLoading(true);
      const res = await Contacts.getContactsAsync({
        pageOffset: offset,
        pageSize: limit,
      })
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
      if (res?.data?.length > 0) {
        setPagination((prev) => {
          return {
            offset: offset,
            limit: limit,
            total: !prev.total ? res?.total : prev.total,
          };
        });
        setContacts(
          res?.data?.map((contact) => ({
            ...contact,
            selected: false,
          }))
        );
      }
    }
    setLoaded(true);
  };

  const fetchInvitedContacts = async () => {
    try {
      const invitedContactIds = await AsyncStorage.getItem("invitedContacts");
      if (invitedContactIds) {
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
      }
    } catch (error) {
      console.error("Error fetching invited contacts", error);
    }
  };

  const handleCheckboxChange = (contactId) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) => {
        if (contact.id === contactId) {
          return {
            ...contact,
            selected: !contact.selected,
          };
        }
        return contact;
      })
    );
  };

  const handleInviteButtonPress = () => {
    const selectedContacts = contacts.filter((contact) => contact.selected);
    sendInvitation(selectedContacts);
  };

  const sendInvitation = async (selectedContacts) => {
    const formattedContacts = selectedContacts.map((contact) => ({
      name: contact.name,
      mobile:
        contact.phoneNumbers && contact.phoneNumbers.length > 0
          ? contact.phoneNumbers[0].number
          : "",
    }));
    const apiEndpoint = apiURIV2 + "/invites";
    const userToken = await isSignedIn();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const response = await axiosInstance.post(
        apiEndpoint,
        { contacts: formattedContacts },
        requestOptions
      );
      if (response.status === 200) {
        const invitedContactIds = selectedContacts.map((contact) => contact.id);
        await AsyncStorage.setItem(
          "invitedContacts",
          JSON.stringify(invitedContactIds)
        );
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
        console.log("Invitation sent successfully");
      }
    } catch (error) {
      console.error("Error sending invitation 2:", error);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Sending Invitation"
        ),
      });
    }
  };

  // if(contacts.length === 0){
  //   return <Text>No Contacts</Text>
  // }

  const renderContacts = () => {
    if (!loaded) {
      return (
        <Text
          style={{ textAlign: "center", fontSize: 16, paddingVertical: 30 }}
        >
          Loading...
        </Text>
      );
    } else if (contacts.length === 0) {
      return (
        <Text style={{ padding: 10, textAlign: "center" }}>No Contacts</Text>
      );
    }

    return contacts.map((contact) => (
      <View
        key={contact.id}
        className="py-4 border-b border-gray-200 flex-row items-center w-full"
      >
        <View className="flex-1">
          <Font>{contact.name}</Font>
          <Font>{contact?.phoneNumbers?.[0]?.number}</Font>
        </View>
        {/* {contact.registered ? null : <Text style={styles.warning} >Pending Registration</Text>} */}
        <View className="w-8">
          <Input
            value={contact.selected}
            setValue={(e) => handleCheckboxChange(contact.id)}
            // tintColors={{true:'red'}}
            // style={styles.checkbox}
            type="checkbox"
            // onValueChange={() => {console.log("eddsdsd") ; handleCheckboxChange(contact.id)}}

            // value={contact.checked}
            // onValueChange={() => handleCheckBoxToggle(contact.id)}
          />
        </View>
      </View>
    ));
  };

  return (
    <View className="relative flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isLoading}
              onRefresh={() => {
                setIsLoading(true);
                fetchContacts({
                  limit: pagination.limit,
                  offset: pagination.offset,
                });
                setTimeout(() => setIsLoading(false), 1000);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="lg:my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Your Contacts
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full lg:w-4/6 p-0 lg:pr-4">
              <View className={`w-full mb-4 lg:mb-8 justify-center rounded-lg`}>
                <View className="flex-row pb-4 w-full justify-center lg:justify-end">
                  <Button
                    label="Invited Contacts"
                    type="primary"
                    className="min-w-max px-8 lg:w-60"
                    onPress={() => navigation.navigate("contacted")}
                  />
                </View>
                <View
                  className={`bg-white w-full items-center border border-gray-200 rounded-lg shadow-lg shadow-gray-300 px-3`}
                >
                  <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                    <View style={styles.content}>
                      <View>{renderContacts()}</View>
                    </View>
                    {pagination.limit < pagination.total && (
                      <View className="flex-row justify-between w-full">
                        {pagination.offset > 0 && (
                          <Pressable
                            className="p-4 "
                            onPress={() => {
                              if (pagination.offset > 0) {
                                const from =
                                  pagination.offset === 1
                                    ? 1
                                    : pagination.offset - pagination.limit;
                                const pagObj = {
                                  offset: from,
                                  limit: pagination.limit,
                                  total: pagination.total,
                                };
                                fetchContacts(pagObj);
                              }
                            }}
                          >
                            <Font className="text-app-e"> {"<"} Prev</Font>
                          </Pressable>
                        )}
                        <View className="flex-1" />
                        {pagination.offset + pagination.limit <
                          pagination.total && (
                          <Pressable
                            className="p-4"
                            onPress={() => {
                              if (
                                pagination.offset + pagination.limit <
                                pagination.total
                              ) {
                                const remList =
                                  pagination.total -
                                  (pagination.offset + pagination.limit);
                                const from =
                                  pagination.offset +
                                  (remList > 0 ? pagination.limit : 0);
                                const pagObj = {
                                  offset: from,
                                  limit: pagination.limit,
                                  total: pagination.total,
                                };
                                fetchContacts(pagObj);
                              }
                            }}
                          >
                            <Font className="text-app-e"> Next {">"}</Font>
                          </Pressable>
                        )}
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      <View className="m-4 mx-10 absolute bottom-0 left-0 right-0">
        {contacts.length === 0 ? null : (
          <TouchableOpacity
            className="p-2 bg-app-e rounded shadow-sm"
            onPress={handleInviteButtonPress}
            disabled={!contacts.some((contact) => contact.selected)}
          >
            <Text className="text-center text-white">Invite Contact</Text>
          </TouchableOpacity>
        )}
      </View>
      {showResponseModal?.visible && (
        <ResponseModal
          visible={showResponseModal?.visible}
          onRequestClose={showResponseModal?.onRequestClose}
          bodyText={showResponseModal?.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      {/* {loading && (
        <ActivityIndicator
          color={colors.app.e}
          size={"large"}
          style={{ position: "absolute", alignSelf: "center", top: 50 }}
        />
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 18,
    // fontWeight: 700,
    marginBottom: 10,
  },
  contactContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    marginLeft: 10,
    display: "block",
    borderColor: "red",
    // flex:1
  },
  contactName: {
    fontSize: 16,
  },
  contactNumber: {
    marginLeft: 10,
  },
  content: {
    marginLeft: 20,
  },
  warning: {
    color: "orange",
    textAlign: "center",
    marginRight: 20,
    fontSize: 13,
  },
  space: {
    marginHorizontal: 200,
  },
});
