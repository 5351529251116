import React from "react";

function zero(num, totalLength) {
  return String(num).padStart(totalLength, "0");
}

import {
  Modal,
  Platform,
  Pressable,
  Text,
  TextInput,
  TouchableHighlight,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "./font";
import colors from "../../colors";
import { ScrollView } from "react-native";
import axios from "axios";
import { Picker } from "@react-native-picker/picker";
import ResponseModal from "./general/modals/ResponseModal";
import { ActivityIndicator } from "react-native";
import DateTimePicker from "./general/inputs/DateTimePicker";

export const Switcher = ({
  value,
  setValue = () => null,
  options = [],
  label = null,
}) => {
  return (
    <>
      {label && (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      )}
      <View className="w-full h-10 rounded-lg flex-row overflow-hidden border border-gray-200 divide-x divide-gray-200">
        {options?.map((e, i) => (
          <Pressable
            className={`h-full flex-1 items-center justify-center ${
              value == e?.value
                ? "bg-app-e active:bg-red-600"
                : "bg-white active:bg-gray-100  "
            }`}
            onPress={() => setValue(e?.value)}
            key={i}
          >
            <Font
              weight={600}
              className={`${value === e.value ? "text-app-a" : "text-app-e"}`}
            >
              {e?.label}
            </Font>
          </Pressable>
        ))}
      </View>
    </>
  );
};

export const Button = ({
  type = "regular",
  leftIcon = null,
  rightIcon = null,
  label = "",
  onPress = null,
  shape = "rect",
  iconName = "",
  waitState = false,
  disabled = false,
  showLoaderWithText = false,
  loaderWithText = "",
  style,
  children,
}) => {
  const types = {
    regular: {
      bg: "bg-app-a",
      bgActive: "active:bg-gray-100",
      bgDisabled: "bg-app-a/40",
      text: "text-app-d1",
      textDisabled: "text-app-d1/90",
      color: colors.app.d1,
    },
    disabled: {
      bg: "bg-app-c",
      bgactive: "active:bg-gray-100",
      text: "text-app-d1",
      border: "border-gray-200 ",
    },
    primary: {
      bg: "bg-app-e",
      bgActive: "active:bg-app-e/70",
      bgDisabled: "bg-app-e/40",
      text: "text-app-a",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    accent: {
      bg: "bg-app-d",
      bgActive: "active:bg-d1",
      bgDisabled: "bg-app-d/40",
      text: "text-app-a",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    success: {
      bg: "bg-app-f",
      bgActive: "active:bg-green-600",
      bgDisabled: "bg-app-f/40",
      text: "text-app-a",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    info: {
      bg: "bg-app-g",
      bgActive: "active:bg-blue-600",
      bgDisabled: "bg-app-g/40",
      text: "text-app-a",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    warning: {
      bg: "bg-app-h",
      bgActive: "active:bg-yellow-600",
      bgDisabled: "bg-app-h/40",
      text: "text-app-a",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    "regular-outline": {
      bg: "border-gray-200 bg-app-a3",
      bgActive: "active:bg-gray-100",
      bgDisabled: "bg-app-a/40",
      text: "text-app-d1",
      textDisabled: "text-app-d1/90",
      color: colors.app.d1,
    },
    "primary-outline": {
      bg: "border-app-e",
      bgActive: "active:bg-red-600",
      bgDisabled: "bg-app-e/40",
      text: "text-app-e",
      textDisabled: "text-app-e/90",
      color: colors.app.a,
    },
    "accent-outline": {
      bg: "border-app-d",
      bgActive: "active:bg-d2",
      bgDisabled: "bg-app-d/40",
      text: "text-app-d",
      textDisabled: "text-app-d/90",
      color: colors.app.a,
    },
    "success-outline": {
      bg: "border-app-f",
      bgActive: "active:bg-green-600",
      bgDisabled: "bg-app-f/40",
      text: "text-app-f",
      textDisabled: "text-app-f/90",
      color: colors.app.f,
    },
    "info-outline": {
      bg: "border-app-g",
      bgActive: "active:bg-app-g2",
      bgDisabled: "bg-app-g/40",
      text: "text-app-g active:text-app-a",
      textDisabled: "text-app-g/90",
      color: colors.app.g,
    },
    "warning-outline": {
      bg: "border-app-h",
      bgActive: "active:bg-yellow-600",
      bgDisabled: "bg-app-h/40",
      text: "text-app-h",
      textDisabled: "text-app-h/90",
      color: colors.app.a,
    },
    "primary-regular-outline": {
      bg: "border-gray-200",
      bgActive: "active:bg-red-600",
      bgDisabled: "bg-app-e/40",
      text: "text-app-d1 active:text-app-a",
      textDisabled: "text-app-e/90",
      color: colors.app.a,
    },
  };
  const borderRadius = {
    rect: "rounded-lg",
    "rounded-rect": "rounded-full",
    circ: "rounded-full",
  };
  return (
    <>
      {shape === "circ" ? (
        <View className="justify-center items-center" style={style}>
          <Pressable
            className={`rounded-full h-14 w-14 border border-gray-200 justify-center items-center ${types[type].bg} ${types[type].bgActive}`}
            onPress={waitState ? () => null : onPress}
            disabled={disabled}
          >
            {waitState ? (
              <ActivityIndicator
                animating={waitState}
                size="small"
                color={types[type].color}
              />
            ) : (
              <RemixIcon
                className={`${types[type].text}`}
                name={iconName}
                size={24}
                color={types[type].color}
              />
            )}
          </Pressable>
          <Font
            className={`text-app-d1 break-words whitespace-normal bg-white/80`}
          >
            {label}
          </Font>
        </View>
      ) : (
        <Pressable
          onPress={waitState ? () => null : onPress}
          className={`${
            borderRadius[shape]
          } h-10 items-center justify-center border border-gray-200 ${
            disabled ? types[type].bgDisabled : types[type].bg
          } ${types[type].bgActive} `}
          style={style}
          disabled={disabled}
        >
          <View className="flex-row items-center justify-center">
            {waitState ? (
              <ActivityIndicator
                animating={waitState}
                size="small"
                color={types[type].color}
                className={
                  loaderWithText || showLoaderWithText ? "mr-1.5 -ml-0.5" : ""
                }
              />
            ) : (
              <>
                {leftIcon === null ? null : (
                  <RemixIcon
                    name={leftIcon.name}
                    size={16}
                    className="mr-2"
                    color={leftIcon.color}
                  ></RemixIcon>
                )}
              </>
            )}
            {(waitState && (loaderWithText || showLoaderWithText)) ||
            children ||
            label ? (
              <Font
                weight={600}
                className={`leading-4 ${
                  disabled ? types[type].textDisabled : types[type].text
                }`}
              >
                {waitState
                  ? loaderWithText ||
                    (showLoaderWithText ? children || label : "")
                  : children || label}
              </Font>
            ) : (
              <></>
            )}
            {rightIcon === null ? null : (
              <RemixIcon
                name={rightIcon.name}
                size={16}
                className="ml-2"
                color={rightIcon.color}
              ></RemixIcon>
            )}
          </View>
        </Pressable>
      )}
    </>
  );
};

/**
  <View className="p-4">
      <Pressable className="border border-app-g bg-app-g3 items-center justify-center p-2 rounded-full" onPress={() => setStep(1)}>
          <Font className="text-app-g font-semibold">Upgrade</Font>
      </Pressable>
  </View>
 */

export const Input = ({
  label = null,
  label1 = null,
  type = "text",
  placeholder = "",
  value = null,
  setValue = () => null,
  action = null,
  validation = false,
  note = null,
  config = null,
  style = [],
  min,
  max,
  invalid,
  leftText,
  rightText,
  infoButtonText,
  onPress,
  ...props
}) => {
  props = {
    ...props,
    placeholderTextColor: "#94a3b8",
    editable: props.editable ?? true,
  };
  const [secret, setSecret] = React.useState(true);
  const [valid, setValid] = React.useState(null);
  const [showModal, setShowModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  React.useEffect(() => {
    if (type === "openinghour") {
      if (!value.length) {
        setValue([{ open: "", close: "" }]);
      }
    }
  }, []);
  React.useEffect(() => {
    if (value !== null) {
      if (validation) {
        let mail_format = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g;
        let pass_format =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
        switch (type) {
          case "email":
            if (value == "" || value.match(mail_format)) {
              setValid(true);
            } else {
              setValid(false);
            }
            break;
          case "password":
            if (value == "" || value.match(pass_format)) {
              setValid(true);
            } else {
              setValid(false);
            }
            break;

          default:
            break;
        }
      }
    }
  }, [value, type, validation]);

  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <>
      {showModal && (
        <ResponseModal
          animationType="fade"
          transparent={true}
          visible={showModal?.visible}
          onRequestClose={showModal?.onRequestClose}
          bodyText={showModal?.bodyText}
        />
      )}
      <View
        className="flex-1"
        style={{
          ...styl,
        }}
      >
        {label !== null ? (
          <View className="w-full min-h-[32px] h-max overflow-visible pb-px items-center flex-row">
            <Font className="flex-1 text-app-d1" weight={600}>
              {label}
            </Font>
            {label1 !== null ? (
              <Font className="flex-1 text-app-d1" weight={600}>
                {label1}
              </Font>
            ) : null}
            {type === "checkbox" ? (
              <Pressable
                className={`w-4 h-4 rounded items-center justify-center ${
                  value ? "bg-red-500" : "bg-app-d3 border border-app-d2"
                }`}
                onPress={() => setValue((e) => !e)}
              >
                {value ? (
                  <RemixIcon name="check-fill" color={colors.app.a}></RemixIcon>
                ) : null}
              </Pressable>
            ) : null}
            {type === "toggle" ? (
              <Pressable
                className={`w-9 h-5 rounded-full justify-center ${
                  props.wait
                    ? value
                      ? "items-start"
                      : "items-end"
                    : value
                    ? "items-end"
                    : "items-start"
                }
                    ${value ? "bg-red-500" : "bg-app-d3"}`}
                onPress={() => setValue((e) => !(e || value))}
                disabled={props.disabled || props.wait}
              >
                <View
                  className={`w-4 h-4 bg-white rounded-full mx-[2px]`}
                ></View>
              </Pressable>
            ) : null}
            {type === "openinghour" ? (
              <Pressable
                className="w-12 h-10 items-center justify-center"
                onPress={() => setValue((e) => [...e, { open: "", close: "" }])}
              >
                <RemixIcon
                  name="add-circle-fill"
                  color={colors.app.g}
                ></RemixIcon>
              </Pressable>
            ) : null}
            {!!infoButtonText && (
              <TouchableHighlight
                className="bg-app-d/75 rounded-full w-4 h-4 justify-center items-center"
                onPress={() => {
                  setShowModal({
                    visible: true,
                    onRequestClose: () => {
                      setShowModal({
                        visible: false,
                        onRequestClose: () => null,
                        bodyText: "",
                      });
                    },
                    bodyText: infoButtonText,
                  });
                }}
              >
                <Text className="italic font-bold text-sm text-app-a p-[1px] leading-4">
                  i
                </Text>
              </TouchableHighlight>
            )}

            {/* // openingHour: <Bar />,
                            // availability: <Bar />, */}
          </View>
        ) : (
          <>
            {type === "checkbox" ? (
              <Pressable
                className={`w-5 h-5 rounded  items-center justify-center ${
                  value ? "bg-red-500" : "bg-app-d3"
                }`}
                onPress={() => setValue((e) => !e)}
              >
                {value ? (
                  <RemixIcon name="check-fill" color={colors.app.a}></RemixIcon>
                ) : null}
              </Pressable>
            ) : null}
            {type === "toggle" ? (
              <Pressable
                className={`w-9 h-5 rounded-full justify-center ${
                  props.wait
                    ? value
                      ? "items-start"
                      : "items-end"
                    : value
                    ? "items-end"
                    : "items-start"
                }
                    ${value ? "bg-red-500" : "bg-app-d3"}`}
                onPress={() => setValue((e) => !(e || value))}
                disabled={props.disabled || props.wait}
              >
                <View
                  className={`w-4 h-4 bg-white rounded-full mx-[2px]`}
                ></View>
              </Pressable>
            ) : null}
            {type === "customToggle" ? (
              <Pressable
                className={`w-9 h-5 rounded-full justify-center ${
                  props.wait
                    ? value
                      ? "items-start"
                      : "items-end"
                    : value
                    ? "items-end"
                    : "items-start"
                }
                    ${value ? "bg-red-500" : "bg-app-d3"}`}
                onPress={() => {
                  setValue((e) => !e);
                  onPress();
                }}
                disabled={props.disabled || props.wait}
              >
                <View
                  className={`w-4 h-4 bg-white rounded-full mx-[2px]`}
                ></View>
              </Pressable>
            ) : null}
          </>
        )}
        {type === "text" ? (
          <View
            className={`rounded border flex-row items-center border-gray-200 overflow-hidden h-10 bg-app-a ${
              props.editable ? "" : "bg-app-c3"
            }`}
          >
            {!!leftText && (
              <Font className="leading-4 pl-3 text-app-d1">{leftText}</Font>
            )}
            <TextInput
              placeholder={placeholder}
              className={`flex-1 leading-4 h-full min-w-0 ${
                props.editable ? "" : "text-app-d/60"
              } ${leftText ? "pl-2" : "pl-3"} ${rightText ? "pr-2" : "pr-3"}`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                  outlineWidth: "0px",
                }
              }
              value={value ? value.toString() : ""}
              onChangeText={(e) => setValue(e)}
              editable={props.editable}
              {...props}
            ></TextInput>
            {!!rightText && (
              <Font className="leading-4 pr-3 text-app-d1">{rightText}</Font>
            )}
          </View>
        ) : null}

        {["datetime", "date", "time"].includes(type) ? (
          <View
            className={`rounded px-4 border flex-row items-center border-gray-200 overflow-hidden h-10 bg-app-a ${
              props.editable ? "" : "bg-app-c3"
            }`}
          >
            <DateTimePicker
              type={type}
              onChange={(v) => setValue(v)}
              value={value}
              {...props}
            />
          </View>
        ) : null}
        {type === "longtext" ? (
          <View
            className={`rounded border border-gray-200 overflow-hidden ${
              props.height ? props.height : "h-40"
            } bg-app-a ${props.editable ? "" : "bg-app-c3"}`}
          >
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-full align-start justify-start p-2 min-w-0 ${
                props.editable ? "" : "text-app-d/50"
              }`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                  outlineWidth: "0px",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              multiline={true}
              textAlignVertical="top"
              {...props}
            ></TextInput>
          </View>
        ) : null}
        {type === "email" ? (
          <View
            className={`rounded border  overflow-hidden h-10  bg-app-a ${
              valid !== false && !invalid ? "border-gray-200" : "border-red-500"
            } ${props.editable ? "" : "bg-app-c3"}`}
          >
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-10 px-3 min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              } ${props.editable ? "" : "text-app-d/50"}`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              {...props}
            ></TextInput>
          </View>
        ) : null}
        {type === "phoneNumber" ? (
          <View
            className={`rounded border  overflow-hidden h-10  bg-app-a ${
              valid !== false && !invalid ? "border-gray-200" : "border-red-500"
            } ${props.editable ? "" : "bg-app-c3"}`}
          >
            <TextInput
              placeholder={placeholder}
              className="flex-1 h-10 px-3 min-w-0"
              keyboardType="phone-pad"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              {...props}
            ></TextInput>
          </View>
        ) : null}
        {type === "password" ? (
          <View
            className={`rounded border  overflow-hidden h-10 flex-row bg-app-a ${
              valid !== false && !invalid ? "border-gray-200" : "border-red-500"
            }`}
          >
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-10 px-3 min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              }`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              secureTextEntry={secret}
              {...props}
            ></TextInput>
            <Pressable
              onPress={() => setSecret((e) => !e)}
              className="w-12 h-10 items-center justify-center"
            >
              <RemixIcon
                name={secret ? "eye-off-fill" : "eye-fill"}
                color={colors.app.d2}
                size={18}
              ></RemixIcon>
            </Pressable>
          </View>
        ) : null}
        {type === "number" ? (
          <View
            className={`rounded border flex-row items-center border-gray-200 overflow-hidden h-10 bg-app-a ${
              props.editable ? "" : "bg-app-c3"
            }`}
          >
            {!!leftText && (
              <Font className="leading-4 pl-3 text-app-d1">{leftText}</Font>
            )}
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-full min-w-0 ${
                props.editable ? "" : "text-app-d/50"
              } ${leftText ? "pl-2" : "pl-3"} ${rightText ? "pr-2" : "pr-3"}`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                  outline: "none",
                }
              }
              keyboardType="numeric"
              inputMode="numeric"
              value={value ? value.toString() : ""}
              {...props}
            ></TextInput>
            {!!rightText && (
              <Font className="leading-4 pr-3 text-app-d1">{rightText}</Font>
            )}
          </View>
        ) : null}
        {type === "pricerange" ? (
          <View className="rounded border border-gray-200 overflow-hidden h-10 flex-row divide-x divide-gray-200  bg-app-a">
            <TextInput
              placeholder={placeholder}
              className="flex-1 h-10 px-3 min-w-0"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              keyboardType="decimal-pad"
              value={value[0]}
              onChangeText={(e) => setValue((ee) => [e, ee[1]])}
              {...props}
            ></TextInput>
            <TextInput
              placeholder={placeholder}
              className="flex-1 h-10 px-3 min-w-0"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              keyboardType="decimal-pad"
              onChangeText={(e) => setValue((ee) => [ee[0], e])}
              value={value[1]}
              {...props}
            ></TextInput>
          </View>
        ) : null}
        {type === "rating" ? (
          <View className="flex-row">
            {[1, 2, 3, 4, 5].map((e) => (
              <Pressable onPress={() => setValue(e)} key={e} className="mx-1">
                <RemixIcon
                  name="star-fill"
                  color={value >= e ? colors.app.h : colors.app.d3}
                ></RemixIcon>
              </Pressable>
            ))}
          </View>
        ) : null}
        {type === "smallcounter" ? (
          <View
            className={`rounded border border-gray-200 overflow-hidden h-8 flex-row  bg-app-a`}
          >
            <Pressable
              className="w-8 h-8 items-center justify-center"
              onPress={() => setValue((e) => (e - 1 < 0 ? e : e - 1))}
            >
              <RemixIcon name="subtract-fill" color={colors.app.d1}></RemixIcon>
            </Pressable>
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-8 px-3 text-center min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              }`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || 0}
              onChangeText={(e) =>
                setValue((ee) => [
                  e === ""
                    ? 0
                    : Number.isNaN(Number(e))
                    ? ee
                    : e.length >= 2 && ee == 0
                    ? Number(e.substring(1))
                    : e,
                ])
              }
              {...props}
            ></TextInput>
            <Pressable
              className="w-8 h-8 items-center justify-center"
              onPress={() => setValue((e) => (e + 1 < 10000 ? e + 1 : e))}
            >
              <RemixIcon name="add-fill" color={colors.app.d1}></RemixIcon>
            </Pressable>
          </View>
        ) : null}
        {type === "counter" ? (
          <View
            className={`rounded border border-gray-200 overflow-hidden h-10 flex-row divide-x divide-gray-200  bg-app-a ${
              props.editable ? "" : "bg-app-c3"
            }`}
          >
            <Pressable
              className="w-12 h-10 items-center justify-center"
              onPress={() =>
                props.editable
                  ? setValue((e) => (e - 1 < (min || 0) ? e : e - 1))
                  : null
              }
            >
              <RemixIcon
                name="subtract-fill"
                color={props.editable ? colors.app.d1 : colors.app.d2}
              ></RemixIcon>
            </Pressable>
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-10 px-3 text-center min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              } ${props.editable ? "" : "text-app-d/50"}`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={
                ![null, undefined, NaN].includes(value) ? value.toString() : "0"
              }
              onChangeText={(e) =>
                setValue((ee) =>
                  e === ""
                    ? min || 0
                    : Number.isNaN(Number(e))
                    ? ee
                    : e.length >= 2 && ee == 0
                    ? min || 0 // Number(e.substring(1))
                    : Number(e) <= min
                    ? min
                    : Number(e) >= max
                    ? max
                    : e
                )
              }
              {...props}
            ></TextInput>
            <Pressable
              className="w-12 h-10 items-center justify-center"
              onPress={() =>
                props.editable
                  ? setValue((e) => (e + 1 < (max || 10000) ? e + 1 : e))
                  : null
              }
            >
              <RemixIcon
                name="add-fill"
                color={props.editable ? colors.app.d1 : colors.app.d2}
              ></RemixIcon>
            </Pressable>
          </View>
        ) : null}
        {type === "coupon" ? (
          <View
            className={`rounded border  overflow-hidden h-10 flex-row divide-x divide-gray-200 bg-app-a ${
              valid !== false ? "border-gray-200" : "border-red-500"
            }`}
          >
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-10 px-3 min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              }`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              {...props}
            ></TextInput>
            <Pressable
              onPress={action}
              className="w-16 h-10 items-center justify-center bg-app-e active:bg-red-600"
            >
              <Font weight={600} className="text-white">
                Apply
              </Font>
            </Pressable>
          </View>
        ) : null}
        {type === "openinghour"
          ? value?.length > 0 &&
            value?.map((e, i) => (
              <View
                className={`rounded border border-gray-200 overflow-hidden h-10 flex-row items-center mb-2 divide-x divide-gray-200 bg-app-a`}
                key={i}
              >
                <TextInput
                  placeholder={placeholder}
                  className={`flex-1 h-10 px-3 min-w-0 text-center`}
                  style={
                    Platform.OS === "web" && {
                      outlineColor: "transparent",
                    }
                  }
                  value={e.open || ""}
                  onChangeText={(e) =>
                    setValue((ee) =>
                      Object.values({
                        ...ee,
                        [i]: {
                          ...ee[i],
                          open: e,
                        },
                      })
                    )
                  }
                  {...props}
                ></TextInput>

                <TextInput
                  placeholder={placeholder}
                  className={`flex-1 h-10 px-3 min-w-0 text-center`}
                  style={
                    Platform.OS === "web" && {
                      outlineColor: "transparent",
                    }
                  }
                  value={e.close || ""}
                  onChangeText={(e) =>
                    setValue((ee) =>
                      Object.values({
                        ...ee,
                        [i]: {
                          ...ee[i],
                          close: e,
                        },
                      })
                    )
                  }
                  {...props}
                ></TextInput>

                <Pressable
                  className="w-12 h-10 items-center justify-center"
                  onPress={() =>
                    setValue((ee) => [...ee.filter((eee, iii) => iii !== i)])
                  }
                >
                  <RemixIcon
                    name="close-fill"
                    color={colors.app.d1}
                  ></RemixIcon>
                </Pressable>
              </View>
            ))
          : null}
        {!!note && (
          <View className="py-1">
            <Font className="text-app-e">{note}</Font>
          </View>
        )}
      </View>
    </>
  );
};

export const Select = ({
  value = null,
  setValue = () => null,
  options = [],
  style = [],
  label = null,
  placeholder = "Select item",
  placeholderEnabled = true,
  mode = "dialog",
  note = "",
  enabled = true,
  ...props
}) => {
  // const trigger = React.useRef();
  // const [open, setOpen] = React.useState(false);
  // const [filter, setFilter] = React.useState("");
  // const [position, setPosition] = React.useState({
  //   x: 0,
  //   y: 0,
  //   w: 0,
  //   h: 0,
  // });
  // const openDropdown = () => {
  //   setFilter("");
  //   trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
  //     setPosition({
  //       x: _px,
  //       y: py,
  //       w: _w,
  //       h: h,
  //     });
  //   });
  //   setOpen(true);
  // };

  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];

  // const getOptions = (UI) => {
  //   if (mode === "search") {
  //     return options
  //       ?.filter((e) => (filter === "" ? e : e.label.includes(filter)))
  //       ?.map((e, i) => UI(e, i));
  //   }
  //   return options?.map((e, i) => UI(e, i));
  // };
  let selectStyle = {
    padding: 0,
    borderWidth: 0,
    outlineColor: "transparent",
    opacity: enabled ? 1 : 0.6,
    backgroundColor: "transparent",
  };
  if (Platform.OS === "web") {
    selectStyle.marginVertical = 0;
    selectStyle.color = enabled ? (value ? "" : colors.app.d1) : colors.app.d;
    selectStyle.width = "100%";
  } else {
    selectStyle.marginVertical = -10;
  }

  // selectStyle = [selectStyle, ...style];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1 capitalize text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      <View
        className={`border border-app-c rounded h-10 overflow-hidden justify-center items-stretch flex-col ${
          Platform.OS === "web" ? "px-2" : "pl-1"
        } ${enabled ? "bg-app-a" : "bg-app-c3"}`}
      >
        <Picker
          style={selectStyle}
          itemStyle={selectStyle}
          mode={mode}
          selectedValue={value || placeholder}
          onValueChange={(v, i) => (enabled ? setValue(v, i) : () => null)}
          enabled={enabled}
          {...props}
        >
          {!!placeholder && (
            <Picker.Item
              key={"" + Date.now()}
              label={placeholder.toString()}
              value={""}
              color={colors.app.d2}
              enabled={Platform.OS === "android" ? false : placeholderEnabled}
            />
          )}
          {options ? (
            options?.map((v) =>
              typeof v === "object" ? (
                <Picker.Item
                  key={v.value}
                  label={v.label + ""}
                  value={v.value}
                />
              ) : (
                <Picker.Item key={v} label={v + ""} value={v} />
              )
            )
          ) : (
            <></>
          )}
        </Picker>
      </View>
      {!!note && (
        <View className="py-1">
          <Font className="text-app-e">{note}</Font>
        </View>
      )}
      {/* <Pressable
        className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 h-10 px-2 min-w-0 items-center flex-row">
          {value ? (
            <Font weight={500} className="text-app-d flex-1">
              {value.label}
            </Font>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1">
              {placeholder}
            </Font>
          )}
          <RemixIcon
            name="arrow-drop-down-line"
            color={colors.app.d1}
          ></RemixIcon>
        </View>
      </Pressable> */}
      {/* <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0 "
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: position.y + 4,
            left: position.x,
            width: position.w,
            maxHeight: 200,
          }}
          className="bg-app-a rounded border border-gray-200"
        >
          {mode == "search" ? (
            <View className="border-b border-gray-200 h-10 p-2">
              <TextInput
                className="flex-1"
                style={
                  Platform.OS === "web" && {
                    outlineColor: "transparent",
                  }
                }
                placeholder="Filter options"
                autoFocus
                onChangeText={(e) => setFilter(e)}
              ></TextInput>
            </View>
          ) : null}
          <ScrollView
            className=""
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              {getOptions((e, i) => (
                <Pressable
                  key={i}
                  className="p-2 w-full"
                  onPress={() => {
                    setValue(e);
                    setOpen(false);
                  }}
                >
                  <View>
                    <Font>{e.label}</Font>
                  </View>
                </Pressable>
              ))}
            </View>
          </ScrollView>
        </View>
      </Modal> */}
    </View>
  );
};

export const SelectLocation = ({
  value = null,
  setValue = null,
  style = [],
  label = null,
  placeholder = null,
}) => {
  const trigger = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  const openDropdown = () => {
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  const Search = async () => {
    if (!filter) return;
    let result = await axios.get(
      `https://nominatim.openstreetmap.org/search?q=${filter}&limit=5&format=json&addressdetails=1`
    );
    setOptions(result?.data);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      <Pressable
        className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 h-10 px-2 min-w-0 items-center flex-row">
          {value ? (
            <Font weight={500} className="text-app-d flex-1" numberOfLines={1}>
              {value?.display_name}
            </Font>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1">
              {placeholder}
            </Font>
          )}
          <RemixIcon
            name="arrow-drop-down-line"
            color={colors.app.d1}
          ></RemixIcon>
        </View>
      </Pressable>
      <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0 "
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: 200,
          }}
          className="bg-app-a rounded border border-gray-200"
        >
          <View className="border-b border-gray-200 h-10 p-2">
            <TextInput
              className="flex-1"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              placeholder="Type location"
              value={filter}
              autoFocus
              onChangeText={(e) => {
                setFilter(e);
                if (filter !== "") setOptions([]);
              }}
              onSubmitEditing={Search}
            ></TextInput>
          </View>
          <ScrollView
            className=""
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              {options.length ? (
                options?.map((e, i) => (
                  <Pressable
                    key={i}
                    className="p-2 w-full"
                    onPress={() => {
                      setValue(e);
                      setOpen(false);
                    }}
                  >
                    <View>
                      <Font>{e?.display_name}</Font>
                    </View>
                  </Pressable>
                ))
              ) : (
                <View className="p-2">
                  <Font>Hit enter to search</Font>
                </View>
              )}
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};
// function C24h(time) {
//     let hours = time?.substr(0, 2);
//     let hourss = parseInt(hours)
//     if (time?.indexOf("AM") != -1 && hourss == 12) {
//         time = time?.replace("12", "0");
//     }
//     if (time?.indexOf("PM") != -1 && hourss < 12) {
//         time = time?.replace(hours, hourss + 12);
//         console.log(time)
//     }
//     return time?.replace(/(AM|PM)/, "");
// }
export const OpeningHours = ({
  value = [],
  setValue = null,
  style = [],
  label = null,
  placeholder = null,
}) => {
  const trigger = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [time, setTime] = React.useState([]);
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  React.useEffect(() => {
    if (value.length > 0) {
      let temp = value.map((e) => {
        let open = value.open.split(":").map((e) => parseInt(e));
        let close = value.close.split(":").map((e) => parseInt(e));
        return {
          oh: open[0],
          om: open[1],
          ch: close[0],
          cm: close[1],
        };
      });
      setTime(temp);
    }
  }, []);
  React.useEffect(() => {
    if (time.length > 0) {
      let temp = time.map((e) => ({
        open: zero(e?.oh, 2) + ":" + zero(e?.om, 2),
        close: zero(e?.ch, 2) + ":" + zero(e?.cm, 2),
      }));
      setValue(temp);
    } else {
      setValue([]);
    }
  }, [time]);
  const openDropdown = () => {
    setTime({
      oh: 0,
      om: 0,
      ch: 0,
      cm: 0,
    });
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      {time.map((e, i) => (
        <View key={i}>
          <Pressable
            className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
            ref={trigger}
            onPress={openDropdown}
          >
            <View className="flex-1 h-10 pl-2 min-w-0 items-center flex-row">
              {value.length >= 0 && value[i] ? (
                <Font
                  weight={500}
                  className="text-app-d flex-1"
                  numberOfLines={1}
                >
                  {zero(e?.oh, 2) + ":" + zero(e?.om, 2)}
                  {" - "}
                  {zero(e?.ch, 2) + ":" + zero(e?.cm, 2)}
                </Font>
              ) : (
                <Font weight={500} className="text-app-d1 flex-1">
                  Opening - Closing
                </Font>
              )}
              {open || (value.length >= 0 && !value[i]) ? null : (
                <Pressable className="p-2" onPress={() => setTime(null)}>
                  <RemixIcon
                    name="close-line"
                    color={colors.app.d1}
                    size={16}
                  ></RemixIcon>
                </Pressable>
              )}
            </View>
          </Pressable>
          <Modal visible={open} transparent animationType="none">
            <Pressable
              className="absolute w-full h-full top-0 left-0 "
              onPress={() => setOpen(false)}
            ></Pressable>
            <View
              style={{
                top: position.y,
                left: position.x,
                width: position.w,
                maxHeight: 200,
              }}
              className="bg-transparent border border-gray-200 rounded"
            >
              <View className="border-b  border-gray-200 h-10 p-2 bg-transparent"></View>
              <ScrollView
                className="bg-app-a"
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View className="flex-1 w-full divide-y divide-gray-200">
                  <View className="w-full">
                    <View className="flex-row w-full items-center">
                      <Font
                        className="text-xs  text-app-d1 flex-1 px-2"
                        weight={500}
                      >
                        Opening Time
                      </Font>
                    </View>
                    <View className="flex-row items-center h-10 w-full ">
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              oh: e.oh - 1 < 0 ? 23 : e.oh - 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.oh, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              oh: e.oh + 1 >= 24 ? 0 : e.oh + 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      <View className="px-1">
                        <Font className="text-app-d" weight={700}>
                          :
                        </Font>
                      </View>
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              om: e.om - 5 < 0 ? 60 - 5 : e.om - 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.om, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              om: e.om + 5 >= 60 ? 0 : e.om + 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      {/* <Pressable><Font>PM</Font></Pressable> */}
                    </View>
                  </View>
                  <View className="w-full">
                    <View className="flex-row w-full items-center">
                      <Font
                        className="text-xs  text-app-d1 flex-1 px-2"
                        weight={500}
                      >
                        Closing Time
                      </Font>
                    </View>
                    <View className="flex-row items-center h-10 w-full ">
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              ch: e.ch - 1 < 0 ? 23 : e.ch - 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.ch, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              ch: e.ch + 1 >= 24 ? 0 : e.ch + 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      <View className="px-1">
                        <Font className="text-app-d" weight={700}>
                          :
                        </Font>
                      </View>
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              cm: e.cm - 5 < 0 ? 60 - 5 : e.cm - 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.cm, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              cm: e.cm + 5 >= 60 ? 0 : e.cm + 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      {/* <Pressable><Font>PM</Font></Pressable> */}
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
          </Modal>
        </View>
      ))}
    </View>
  );
};
export const OpeningHoursSlot = ({
  value = null,
  setValue = null,
  style = [],
  label = null,
  slot = 1,
  placeholder = null,
}) => {
  const trigger = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [time, setTime] = React.useState(null);
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  React.useEffect(() => {
    if (value !== null) {
      let open = value.open.split(":").map((e) => parseInt(e));
      let close = value.close.split(":").map((e) => parseInt(e));
      setTime({
        oh: open[0],
        om: open[1],
        ch: close[0],
        cm: close[1],
        sl: slot,
      });
    }
  }, []);
  React.useEffect(() => {
    if (time !== null) {
      setValue({
        open: zero(time?.oh, 2) + ":" + zero(time?.om, 2),
        close: zero(time?.ch, 2) + ":" + zero(time?.cm, 2),
      });
    } else {
      setValue(null);
    }
  }, [time]);
  const openDropdown = () => {
    setTime({
      oh: 0,
      om: 0,
      ch: 0,
      cm: 0,
      sl: 1,
    });
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      <Pressable
        className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 h-10 min-w-0 items-center flex-row">
          {value !== null ? (
            <>
              <View className="p-2">
                <Font
                  weight={500}
                  className="text-app-d flex-1"
                  numberOfLines={1}
                >
                  {zero(time?.oh, 2) + ":" + zero(time?.om, 2)}
                  {" - "}
                  {zero(time?.ch, 2) + ":" + zero(time?.cm, 2)}
                </Font>
              </View>
              <View className="flex-1"></View>
              <View className="bg-gray-200 w-10 h-10  items-center justify-center">
                <Font>{time?.sl}</Font>
              </View>
            </>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1 p-2">
              Opening - Closing | Slot
            </Font>
          )}
          {open || !value ? null : (
            <Pressable className="p-2" onPress={() => setTime(null)}>
              <RemixIcon
                name="close-line"
                color={colors.app.d1}
                size={16}
              ></RemixIcon>
            </Pressable>
          )}
        </View>
      </Pressable>
      <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0 "
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: 220,
          }}
          className="bg-transparent border border-gray-200 rounded"
        >
          <View className="border-b  border-gray-200 h-10 p-2 bg-transparent"></View>
          <ScrollView
            className="bg-app-a"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Opening Time
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          oh: e.oh - 1 < 0 ? 23 : e.oh - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.oh, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          oh: e.oh + 1 >= 24 ? 0 : e.oh + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  <View className="px-1">
                    <Font className="text-app-d" weight={700}>
                      :
                    </Font>
                  </View>
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          om: e.om - 5 < 0 ? 60 - 5 : e.om - 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.om, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          om: e.om + 5 >= 60 ? 0 : e.om + 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  {/* <Pressable><Font>PM</Font></Pressable> */}
                </View>
              </View>
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Closing Time
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          ch: e.ch - 1 < 0 ? 23 : e.ch - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.ch, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          ch: e.ch + 1 >= 24 ? 0 : e.ch + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  <View className="px-1">
                    <Font className="text-app-d" weight={700}>
                      :
                    </Font>
                  </View>
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          cm: e.cm - 5 < 0 ? 60 - 5 : e.cm - 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.cm, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          cm: e.cm + 5 >= 60 ? 0 : e.cm + 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  {/* <Pressable><Font>PM</Font></Pressable> */}
                </View>
              </View>
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Available Slot
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          sl: e.sl <= 1 ? 1 : e.sl - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {time?.sl}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          sl: e.sl >= 99 ? 99 : e.sl + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};

export const DateRange = (
  value = [],
  placeholder = [],
  setValue = null,
  min = null,
  max = null,
  label = null
) => {};
