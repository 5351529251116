import React, { useCallback } from "react";
import {
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { useListingContext } from "../../contexts/listing-context";
import { useSharedContext } from "../../contexts/shared-context";
import { useState } from "react";
import { isSignedIn } from "../../actions/auth-actions";
import { apiURIV2 } from "../../constants";
import { ActivityIndicator } from "react-native";
import { useEffect } from "react";
import axiosInstance from "../../constants/axios-config";
import PlanCard from "../../components/general/cards/plan-card";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import { merchantIdentifier, stripePublishableKey } from "../../constants";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { Button } from "../../components/input";
import { getFullDateTime } from "../../utils/date-time-util";
import moment from "moment";
import { useStripeContext } from "../../contexts/stripe-context";
import PlanStatusChip from "../../components/general/status-chips/plan-status-chip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/service/CheckoutForm";
import { catchAPIErrorText } from "../../utils/error-handler";
// import { StripeProvider, useStripe } from "@stripe/stripe-react-native";

export default function ManageSubscription({ navigation, route }) {
  //   const { initPaymentSheet, presentPaymentSheet } = useStripe();
  const { validateStripeUserBankAccountStatus } = useStripeContext();

  const stripePromise = React.useMemo(
    () =>
      loadStripe(
        // "pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
        stripePublishableKey
      ),
    []
  );

  const { listingPackages, setListingPackages } = useListingContext();
  const [postSubscription, setPostSubscription] = React.useState({
    isLoading: false,
    isCancelLoading: false,
  });
  const [subscriptionInfo, setSubscriptionInfo] = React.useState(null);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    message: "Test Message",
  });
  const [checkoutData, setCheckoutData] = useState({
    visible: false,
    paymentIntentId: undefined,
    clientSecret: undefined,
    isReloadedPage: false,
    package_id: null,
    price_id: null,
  });
  const [lPIsLoading, setLPIsLoading] = useState(false);
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(true);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  React.useEffect(() => {
    const clientSecret = route.params?.payment_intent_client_secret;

    if (!clientSecret) {
      return;
    }
    setCheckoutData((v) => ({
      ...v,
      clientSecret: clientSecret,
      visible: true,
      isReloadedPage: true,
    }));
  }, []);

  const getListingPackages = React.useCallback(async () => {
    setLPIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/packages", {
        params: { package_item_type: "listing", currency: "USD" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.response", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Packages"),
      });
    } finally {
      setListingPackages({ data });
      setLPIsLoading(false);
    }
  });
  const getCurrentSubscription = React.useCallback(async () => {
    setSubscriptionIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/package-subscriptions/current",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setSubscriptionInfo(res.data?.data);
        if (res.data.data?.package_subscription?.status !== "active") {
          setCheckoutData((e) => ({
            ...e,
            package_id: res.data.data?.package_subscription?.package_id,
            price_id: res.data.data?.package_subscription?.price_id,
          }));
        }
        if (res.data?.data?.subscription_ref?.scheduled_subscription_phase) {
          setCheckoutData((e) => ({
            ...e,
            package_id:
              res.data?.data?.subscription_ref?.scheduled_subscription_phase
                ?.price_ref?.product_ref?.package_id,
            price_id:
              res.data?.data?.subscription_ref?.scheduled_subscription_phase
                ?.price_ref?.product_ref?.package_price_id,
          }));
        }
      } else {
        throw new Error("No Active Subscription Data Found");
      }
    } catch (e) {
      console.log("manage-subscription, getCurrentSubscription error : ", e);
      if (e?.response?.status === 404) {
        setSubscriptionInfo({});
        validateStripeUserBankAccountStatus(true);
        return;
      }
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Current Subscription"
        ),
      });
    } finally {
      setSubscriptionIsLoading(false);
    }
  });

  useEffect(() => {
    const getData = async () => {
      await getListingPackages();
      await getCurrentSubscription();
    };
    getData();
  }, []);

  const postCreateSubscription = React.useCallback(async () => {
    if (checkoutData.clientSecret) {
      if (checkoutData.visible) {
        setCheckoutData((v) => ({
          ...v,
          visible: false,
        }));
      }
      setTimeout(() => {
        setCheckoutData((v) => ({
          ...v,
          visible: true,
          isReloadedPage: true,
        }));
      }, 2000);
      return;
    }
    setPostSubscription({ isLoading: true, isCancelLoading: false });
    let data;
    try {
      const useToken = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 +
          `/packages/${checkoutData?.package_id}/prices/${checkoutData?.price_id}/subscribe`,
        {},
        {
          headers: {
            Authorization: `Bearer ${useToken}`,
          },
        }
      );
      if (res.status === 200) {
        // setConfirmModal({ show: true, message: "Test Message" });
        const clientSecret =
          res.data.data.payment_intent ||
          res.data.data.subscription.latest_invoice.setup_intent.client_secret;
        const paymentIntentId =
          res.data?.data?.subscription?.latest_invoice?.payment_intent?.id;
        if (res.data.data.payment_intent) {
          setCheckoutData((v) => ({
            ...v,
            paymentIntentId: paymentIntentId,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: false,
          }));
          navigation.setParams({
            payment_intent: paymentIntentId || "",
            payment_intent_client_secret: clientSecret,
            redirect_status: "initiated",
          });
        } else if (res.data.data.setup_intent) {
          setCheckoutData((v) => ({
            ...v,
            paymentIntentId: paymentIntentId,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: false,
          }));
          navigation.setParams({
            payment_intent: paymentIntentId || "",
            payment_intent_client_secret: clientSecret,
            redirect_status: "initiated",
          });
        } else {
          if (res.data?.data) {
            setSubscriptionInfo(res.data?.data);
          }
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
                dismissText: undefined,
              });
            },
            bodyText: res.data?.message || "Successfully created subscription",
            dismissText: "Okay",
          });
        }
      }
    } catch (e) {
      console.log("manage-subscription, postCreateSubscription error", e);
      if (e?.response?.status) {
        console.log(
          "manage-subscription, postCreateSubscription error",
          e.response.data
        );
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: catchAPIErrorText(e),
        });
      } else {
        console.log("manage-subscription, postCreateSubscription error", {
          ...e,
        });
      }
    } finally {
      setPostSubscription({ isLoading: false, isCancelLoading: false });
    }
  });

  const cancelSubscription = React.useCallback(async () => {
    setPostSubscription((e) => ({ ...e, isCancelLoading: true }));
    try {
      const useToken = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + `/package-subscriptions/current/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${useToken}`,
          },
        }
      );
      if (res.status === 200) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: res.data?.message || "Successfully canceled subscription",
          dismissText: "Okay",
        });
      }
    } catch (e) {
      if (e?.response?.status) {
        console.log(
          "manage-subscription, cancelSubscription error",
          e.response.data
        );
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: catchAPIErrorText(e),
        });
      } else {
        console.log("manage-subscription, cancelSubscription error", { ...e });
      }
    } finally {
      setPostSubscription((e) => ({ ...e, isCancelLoading: false }));
    }
  });

  const onPaymentSuccess = useCallback(() => {
    navigation.setParams({
      payment_intent: undefined,
      payment_intent_client_secret: undefined,
      redirect_status: undefined,
    });
    getCurrentSubscription();
    setCheckoutData((v) => ({
      ...v,
      visible: false,
      clientSecret: undefined,
      isReloadedPage: false,
      package_id: undefined,
      price_id: undefined,
    }));
  }, []);

  const onPaymentError = useCallback((error, paymentIntent) => {
    console.log("onPaymentError:", { ...error }, paymentIntent);
    if (
      (error.payment_intent.status || error?.code || "").toLowerCase() ===
      "canceled"
    ) {
      setOrderCheckoutState((v) => ({
        ...v,
        customer: undefined,
        ephemeralKey: undefined,
        paymentIntentId: undefined,
        clientSecret: undefined,
        orderId: null,
        visible: false,
        isReloadedPage: false,
      }));
    }
    setShowResponseModal({
      visible: true,
      onRequestClose: () => {
        setShowResponseModal({
          visible: false,
          onRequestClose: () => null,
          bodyText: "",
          dismissText: undefined,
        });
      },
      bodyText: `Payment canceled by Stripe\n\n${error.message}`,
    });
  }, []);

  if (subscriptionIsLoading) {
    return (
      <View className="flex-1 justify-center items-center rounded-full bg-transparent">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={subscriptionIsLoading}
          size="large"
          color={colors.app.e}
          style={{ marginBottom: 4 }}
        />
      </View>
    );
  }

  return (
    <>
      <ScrollView
        className="px-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
          padding: 16,
        }}
        nativeID="checkout"
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={
                listingPackages &&
                lPIsLoading &&
                subscriptionInfo &&
                subscriptionIsLoading
              }
              onRefresh={() => {
                setCheckoutData((v) => ({
                  ...v,
                  package_id: undefined,
                  price_id: undefined,
                  visible: false,
                }));
                getCurrentSubscription();
                getListingPackages();
              }}
            />
          )
        }
      >
        {checkoutData.visible && checkoutData.clientSecret ? (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: checkoutData.clientSecret,
              appearance: {
                theme: "stripe",
              },
            }}
          >
            <CheckoutForm
              show={checkoutData.visible}
              isReloadedPage={checkoutData.isReloadedPage}
              onSccess={onPaymentSuccess}
              onRequestClose={() =>
                setCheckoutData((v) => ({ ...v, visible: false }))
              }
              onError={onPaymentError}
            />
          </Elements>
        ) : (
          <></>
        )}
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Manage Subscription
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full m-0 p-0">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Current Package">
                <View className="flex-row flex-wrap px-4 pb-4 w-full ">
                  <View className="p-4 w-full border border-gray-200 rounded-lg bg-gray-50 lg:flex-row">
                    {subscriptionInfo ? (
                      Object.keys(subscriptionInfo?.subscription || {})
                        ?.length ? (
                        <View className="flex-col justify-between flex-auto">
                          <View className="w-full pt-2 flex-wrap">
                            <Font
                              weight={600}
                              className="font-semibold text-app-d text-lg leading-5 capitalize pb-2"
                            >
                              {subscriptionInfo?.subscription?.plan?.nickname}
                            </Font>
                            <Font
                              className="text-sm text-app-d1 uppercase"
                              weight={600}
                            >
                              {subscriptionInfo?.subscription?.plan?.currency}{" "}
                              {subscriptionInfo?.subscription?.plan?.amount /
                                100}
                            </Font>
                          </View>
                          <View className="w-full pt-2 flex-row">
                            <PlanStatusChip
                              status={
                                subscriptionInfo?.package_subscription?.status
                              }
                            />
                          </View>
                          <View className="w-full pb-4 pt-2">
                            <Font className="text-sm text-app-d1" weight={600}>
                              {subscriptionInfo?.package_subscription
                                ?.status === "active"
                                ? "Effective from:"
                                : "Status as of:"}
                            </Font>
                            <Font className="text-sm text-app-d1" weight={600}>
                              {getFullDateTime(
                                subscriptionInfo?.subscription
                                  ?.current_period_start * 1000
                              )}
                            </Font>
                          </View>
                          {subscriptionInfo?.package_subscription?.status ===
                          "active" ? (
                            <View className="w-full py-2">
                              <Font
                                className="text-sm text-app-d1"
                                weight={600}
                              >
                                {subscriptionInfo?.subscription_ref
                                  ?.scheduled_subscription_phase
                                  ? `Subscription ends ${moment(
                                      subscriptionInfo?.subscription
                                        ?.current_period_end * 1000
                                    ).from()}`
                                  : `Next invoice on: ${getFullDateTime(
                                      subscriptionInfo?.subscription
                                        ?.current_period_end * 1000
                                    )}`}
                              </Font>
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>
                      ) : (
                        <View className="flex-1 justify-center items-center h-14">
                          <Font className="text-app-d1">
                            Current Package Subscription Not Found
                          </Font>
                        </View>
                      )
                    ) : (
                      <View className="flex-1 justify-center items-center h-14">
                        <Font>Loading...</Font>
                      </View>
                    )}
                  </View>
                </View>
              </Card>
              <Card title="Choose Package" className="flex-1 h-full w-full">
                <View className="w-full flex-row flex-wrap p-4">
                  {listingPackages && listingPackages?.length > 0 ? (
                    <>
                      {/* <FlatList
                      data={listingPackages}
                      key={screen?.width}
                      numColumns={screen?.width >= 768 ? 2 : 1}
                      renderItem={({
                        item: {
                          id,
                          title,
                          subTitle,
                          is_featured,
                          features,
                          prices,
                        },
                      }) => ()}
                      /> */}
                      {listingPackages.map((e, i) => (
                        <View
                          className="w-full md:w-1/2 mb-4 md:m-0 md:p-4"
                          key={`listingPackages_${i}`}
                        >
                          <PlanCard
                            planTitle={e.title}
                            planPrice={`${e?.prices[0]?.currency_symbol} ${
                              e?.prices[0]?.discounted_price / 100
                            }`}
                            planSubtitle={e.subTitle}
                            planFeatures={e.features}
                            isCurrentPlan={
                              e?.id ===
                                subscriptionInfo?.package_subscription
                                  ?.package_id &&
                              subscriptionInfo?.package_subscription?.status ===
                                "active"
                            }
                            isSelected={e.id === checkoutData?.package_id}
                            status={
                              subscriptionInfo?.package_subscription?.status
                            }
                            // scheduledInfo={
                            //   subscriptionInfo?.subscription?.schedule
                            // }
                            nextRenewalDate={
                              e.id ===
                                subscriptionInfo?.subscription_ref
                                  ?.scheduled_subscription_phase?.price_ref
                                  ?.product_ref?.package_id &&
                              +subscriptionInfo?.subscription_ref
                                ?.scheduled_subscription_phase?.start_date
                            }
                            onPress={() => {
                              if (
                                e.id ===
                                  subscriptionInfo?.package_subscription
                                    ?.package_id &&
                                subscriptionInfo?.package_subscription
                                  ?.status === "active"
                              ) {
                                setCheckoutData((prev) => ({
                                  ...prev,
                                  package_id: undefined,
                                  price_id: undefined,
                                }));
                              } else {
                                setCheckoutData((prev) => ({
                                  ...prev,
                                  package_id: e.id,
                                  price_id: e?.prices[0]?.id,
                                }));
                              }
                            }}
                            showButton={false}
                            isFeatured={e?.is_featured}
                          />
                        </View>
                      ))}
                      <View className="w-full lg:flex-row-reverse lg:justify-around pt-4">
                        <View className="py-2 lg:ml-2 flex-1 lg:max-w-sm">
                          <Button
                            shape="rounded-rect"
                            type="primary"
                            onPress={postCreateSubscription}
                            waitState={postSubscription.isLoading}
                            disabled={
                              !!(
                                (subscriptionInfo?.package_subscription
                                  ?.status === "active" &&
                                  (checkoutData?.package_id ===
                                    subscriptionInfo?.package_subscription
                                      ?.package_id ||
                                    !checkoutData?.package_id)) ||
                                (checkoutData?.package_id ===
                                  subscriptionInfo?.subscription_ref
                                    ?.scheduled_subscription_phase?.price_ref
                                    ?.product_ref?.package_id &&
                                  subscriptionInfo?.subscription_ref
                                    ?.scheduled_subscription_phase?.start_date)
                              )
                            }
                          >
                            {subscriptionInfo?.package_subscription?.status ===
                            "active"
                              ? "Update Subscription"
                              : "Purchase Subscription"}
                          </Button>
                        </View>
                        {subscriptionInfo?.package_subscription?.status ===
                          "active" && (
                          <View className="py-2 lg:mr-2 flex-1">
                            <Button
                              shape="rounded-rect"
                              type="regular-outline"
                              onPress={cancelSubscription}
                              waitState={postSubscription.isCancelLoading}
                            >
                              Cancel Subscription
                            </Button>
                          </View>
                        )}
                      </View>
                    </>
                  ) : listingPackages?.length === 0 && !lPIsLoading ? (
                    <View className="w-full flex-row h-80 justify-center items-center">
                      <Font className="text-app-d1">No packages found.</Font>
                    </View>
                  ) : (
                    <View className="flex-1 w-full justify-center h-40">
                      <ActivityIndicator
                        size={"large"}
                        color={colors.app.e}
                      ></ActivityIndicator>
                    </View>
                  )}
                </View>

                <View className="w-full flex-1 py-3 px-4 bg-app-c1 rounded-b-lg">
                  <Font className="text-app-d1 font-bold">Note :</Font>
                  <Font className="text-app-d1 font-semibold">
                    On cancellation, current subscription will be downgraded at
                    the end of current billing period.
                  </Font>
                </View>
              </Card>
            </View>
            <View className="flex-1 w-full lg:w-2/6 p-0 lg:ml-4">
              <Card title="SMBEx">
                <View className="flex-1 w-full p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      <ConfirmModal
        BodyComponent={""}
        bodyText={confirmModal.message}
        visible={confirmModal.show}
        onConfirm={() => setConfirmModal({ show: false, message: "" })}
        confirmText="Ok"
        // cancelText="Dismiss"
        onCancel={() => setConfirmModal({ show: false, message: "" })}
      />
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
    </>
  );
}
