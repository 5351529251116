import moment from "moment";
import React, { useState } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { apiURIV2 } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { useAuthContext } from "../../contexts/auth-context";
import Toast from "react-native-toast-message";
import ResponseModal from "../../components/general/modals/ResponseModal";

export default function ManageAddresses({ navigation, route }) {
  const [loading, setLoading] = React.useState(false);

  const [addressList, setAddressList] = useState([]);
  const [isDeleteAddressApiLoading, setIsDeleteAddressApiLoading] = useState();
  const [isRefresh, setIsRefresh] = useState(false);

  const checkRefreshList = () => {
    setIsRefresh(!isRefresh);
  };
  const { user } = useAuthContext();
  const [isAddressDeleted, setIsAddressDeleted] = useState({
    isDeleted: false,
    message: null,
    isDeleting: false,
  });
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const showToast = () => {
    Toast.show({
      type: "Success",
      text1: "SuccessFully Deleted",
      text2: "Address deleted successfully!",
    });
  };

  React.useEffect(() => {
    getAddress();
  }, [isRefresh]);

  const getAddress = async () => {
    const token = await isSignedIn();

    setLoading(true);
    try {
      const res = await axiosInstance.get(
        apiURIV2 + "/users/me/addresses/shipping",

        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status === 200) {
        setAddressList(res.data.data);
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
    } finally {
      setLoading(false);
    }
  };

  async function deleteAddressHandler(addressId) {
    const token = await isSignedIn();
    try {
      setIsDeleteAddressApiLoading(true);

      const res = await axiosInstance.delete(
        apiURIV2 + `/users/me/addresses/shipping/${addressId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status === 200) {
        setAddressList((prevData) =>
          prevData?.filter((data) => data.id !== addressId)
        );

        setShowResponseModal(() => ({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Address Deleted SuccessFully",
        }));
      } else {
        console.log(
          "An error occurred while loading the address. : ",
          res.data
        );
      }
    } catch (e) {
      console.log("An error occurred while loading the address. : ", e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Bookings"),
      });
    } finally {
      setIsDeleteAddressApiLoading(false);
    }
  }

  const goToPost = (slug) => {
    console.log(slug);
    navigation.navigate({ name: "post", params: { slug: slug } });
  };
  const extractContent = (s, space) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="lg:my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Manage Addresses
            </Font>
          </View>
          <View className="flex-col justify-start lg:flex-row lg:items-start max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card
                title="Manage Shipping Addresses"
                navigation={navigation}
                Widget={() => (
                  <Pressable onPress={() => navigation.navigate("add-address",{checkRefreshList:checkRefreshList})}>
                    <Image
                      source={require("../../../assets/icon-add.png")}
                      style={{ width: 25, height: 25 }}
                    />
                  </Pressable>
                )}
              >
                <View className="p-4 pt-0 w-full">
                  {addressList?.map((data) => (
                    <View
                      className="border-b border-gray-200 py-4"
                      key={data?.id}
                      // onPress={() => navigation.navigate("update-address")}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Font className="text-app-d" weight={500}>
                          <Font className="text-app-d" weight={700}>
                            {data?.address?.recipient_name}
                          </Font>{" "}
                          {data?.address?.title}
                        </Font>
                        <View style={{ flexDirection: "row" }}>
                          <Pressable
                            style={{ marginRight: 10 }}
                            onPress={() => {
                              deleteAddressHandler(data?.id);
                              // Toast.show({
                              //   type: "success",
                              //   text1: "SuccessFully Deleted",
                              //   text2: "Address deleted successfully!",
                              // });
                            }}
                          >
                            <Image
                              source={require("../../../assets/icon-delete.png")}
                              style={{ width: 20, height: 20 }}
                            />
                          </Pressable>
                          <Pressable
                            onPress={() =>
                              navigation.navigate("update-address", {
                                addressId: data?.id,
                              })
                            }
                          >
                            <Image
                              source={require("../../../assets/icon-edit.png")}
                              style={{ width: 20, height: 20 }}
                            />
                          </Pressable>
                        </View>
                      </View>
                      <Font className="text-app-d my-2" weight={600}>
                        {data?.address?.phone}
                      </Font>
                      <Font className="text-app-d" weight={500}>
                        {`, ${data?.address?.address_line_1}`}

                        {`, ${data?.address?.city}`}
                        {`, ${data?.address?.state}`}
                      </Font>
                      <Font className="text-app-d italic" weight={500}>
                        {`, ${data?.address?.country}`}
                        {`, ${data?.address?.postal_code}`}
                      </Font>
                    </View>
                  ))}
                  {/* <Pressable
                    className="border-b border-gray-200 py-4"
                    onPress={() => navigation.navigate("update-address")}
                  >
                    <Font className="text-app-d" weight={500}>
                      <Font className="text-app-d" weight={700}>
                        Recipient Name
                      </Font>{" "}
                      (Home2)
                    </Font>
                    <Font className="text-app-d my-2" weight={600}>
                      {addressList?.address?.phone}
                    </Font>
                    <Font className="text-app-d" weight={500}>
                      Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi
                      96522
                    </Font>
                  </Pressable> */}
                </View>
              </Card>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      {loading && (
        <ActivityIndicator
          color={colors.app.e}
          size={"large"}
          style={{ position: "absolute", alignSelf: "center", top: 50 }}
        />
      )}
    </View>
  );
}
