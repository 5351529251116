import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Image,
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import UploadPicture from "../../components/uploadpicture";
import { Button, Input } from "../../components/input";
import { DEV_API, getPublicData } from "../../services/api";
import { AuthContext } from "../../contexts/auth-context";
import { apiURIV2 } from "../../constants";
import axios from "axios";
import { getUserProfile, isSignedIn } from "../../actions/auth-actions";
import { createCrossPlatformFormData, fileURLtoBlob } from "../../utils/encDec";
import axiosInstance from "../../constants/axios-config";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";

export default function UpdateProfile({ navigation, route }) {
  const postsLimit = 10;
  // const [first_name,setFirst_name] = useState('')

  // const [posts, setPosts] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const getPosts = async () => {
  //   let data = await getPublicData(
  //     `/posts?postType=blog&_sort=createdAt:DESC&_limit=${postsLimit}&_start=${
  //       page * postsLimit
  //     }`
  //   );
  //   if (data) {
  //     setPosts(data.data);
  //   }
  // };
  // React.useEffect(() => {
  //   getPosts();
  // }, [page]);

  // const {
  //   user: {
  //     email = "",
  //     first_name = "",
  //     last_name = "",
  //     company_name = "",
  //     phone = "",
  //     profile_image_url = "",
  //     about = "",
  //   },
  // } = React.useContext(AuthContext);

  const { user, setUser } = React.useContext(AuthContext);

  const [email, setEmail] = useState(user.email);
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [company_name, setCompanyName] = useState(user.company_name);
  const [phone, setPhone] = useState(user.phone);
  const [profile_image_url, setProfile_image_url] = useState(
    user.profile_image_url
  );
  const [imagetoUpload, selectImageToUpload] = useState(null);
  const [about, setAbout] = useState(user.about);
  const [gettingUserInfo, setGettingUserInfo] = useState();
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const GetUserData = useCallback(async () => {
    setGettingUserInfo(true);
    getUserProfile()
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data.data);
          const updated = res.data.data;
          setEmail(updated.email);
          setFirstName(updated.first_name);
          setLastName(updated.last_name);
          setCompanyName(updated.company_name);
          setPhone(updated.phone);
          setProfile_image_url(updated.profile_image_url);
          setAbout(updated.about);
        }
      })
      .catch((error) => {
        console.log("Error in Getting User Info", error);
      })
      .finally(() => {
        setGettingUserInfo(false);
      });
  });

  useEffect(() => {
    GetUserData();
  }, []);

  // const goToPost = (slug) => {
  //   console.log(slug);
  //   navigation.navigate({ name: "post", params: { slug: slug } });
  // };
  // const extractContent = (s, space) => {
  //   var span = document.createElement("span");
  //   span.innerHTML = s;
  //   if (space) {
  //     var children = span.querySelectorAll("*");
  //     for (var i = 0; i < children.length; i++) {
  //       if (children[i].textContent) children[i].textContent += " ";
  //       else children[i].innerText += " ";
  //     }
  //   }
  //   return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  // };

  const CheckValidation = () => {
    if (first_name == "") {
      alert("First Name is required");
      return;
    } else if (last_name == "") {
      alert("Last Name is required");
      return;
    } else if (company_name == "") {
      alert("Company is required");
      return;
    } else if (email == "") {
      alert("Email is required");
      return;
    } else if (phone == "") {
      alert("Phone is required");
      return;
    } else {
      UpdateProfile();
    }
  };
  const UpdateProfile = async () => {
    let updateObj = {
      email: email,
      phone: phone,
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      about: about,
      profile_image: imagetoUpload,
    };

    const formData = createCrossPlatformFormData({
      blobDataKeys: ["profile_image"],
      data: updateObj,
    });

    const userToken = await isSignedIn();

    axiosInstance
      .patch(`${apiURIV2}/users/me`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data?.data?.message || "Profile Updated!",
        });
        GetUserData();
      })
      .catch((e) => {
        console.log("error", e);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(e, "Unknown Error"),
        });
      });
  };
  const onChangeProfileImage = async (imageObject) => {
    try {
      let path =
        Platform.OS === "ios"
          ? imageObject.uri.replace("file:///", "file:/")
          : imageObject.uri;
      fileURLtoBlob(path)
        .then((data) => {
          imageObject.blob = data;
          selectImageToUpload(imageObject);
        })
        .catch((e) => {
          console.log("Something sent wrong", e);
        });
    } catch (error) {
      console.log("Error converting URI to Blob:", error);
      console.log("Error details:", error.message, error.stack);
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={gettingUserInfo}
              onRefresh={GetUserData}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Update profile
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Update Profile">
                <View className="">
                  <View className="p-4 items-center justify-center">
                    <UploadPicture
                      onImagePick={(data) => onChangeProfileImage(data)}
                      source={profile_image_url}
                    ></UploadPicture>
                  </View>
                  <View className="flex-row flex-wrap mb-4">
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="First Name"
                        value={first_name}
                        setValue={(value) => setFirstName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Last Name"
                        value={last_name}
                        setValue={(value) => setLastName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Company Name"
                        value={company_name}
                        setValue={(value) => setCompanyName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Email"
                        value={email}
                        setValue={(value) => setEmail(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Phone"
                        value={phone}
                        setValue={(value) => setPhone(value)}
                      ></Input>
                    </View>
                    <View className="w-full px-4 py-1">
                      <Input
                        type="longtext"
                        label="About"
                        value={about}
                        setValue={(value) => setAbout(value)}
                      ></Input>
                    </View>
                    <View className="w-full px-4 pt-4 pb-1 flex-row justify-center">
                      <Button
                        className="w-full lg:w-1/2"
                        onPress={() => CheckValidation()}
                        type="primary"
                        label="Save"
                      ></Button>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="flex-1 w-full p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
    </View>
  );
}
