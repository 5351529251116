import { View, Text, Platform } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import StripeCheckout from "../../components/service/stripe-checkout";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { isSignedIn } from "../../actions/auth-actions";
import { apiURIV2 } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import { Font } from "../../components/font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Button, Input } from "../../components/input";
import useInputHandler from "../../utils/useInputHandler";
import { RefreshControl } from "react-native";
import { ScrollView } from "react-native";
import { useAuthContext } from "../../contexts/auth-context";
import { catchAPIErrorText } from "../../utils/error-handler";

const TopupWithdrawal = ({ navigation, route }) => {
  const { setUser } = useAuthContext();
  const [walletStatus, setWalletStatus] = useState({
    amount: 0,
    currency: "USD",
    currency_symbol: "$",
  });
  const [checkoutState, setCheckoutState] = useState({
    customerId: undefined,
    ephemeralKey: undefined,
    paymentIntentId: undefined,
    clientSecret: undefined,
    transactionId: undefined,
    visible: false,
    isReloadedPage: false,
    isLoading: false,
    amount: undefined,
  });
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [refreshing, setRefreshing] = useState(false);
  const [withdrawIsLoading, setWithdrawIsLoading] = useState();
  const [topupIsLoading, setTopupIsLoading] = useState();

  const {
    inputValue: amountInputValue,
    currentValue: amountValue,
    onValueChangeHandler: onChangeAmountText,
    onBlurHandler: onBlurAmount,
    hasError: amountHasError,
    reset: resetAmount,
  } = useInputHandler({ required: true });

  const getWalletBalance = useCallback(async () => {
    try {
      const useToken = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/wallets/mine", {
        headers: {
          Authorization: `Bearer ${useToken}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        setWalletStatus({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUser((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
      }
    } catch (e) {
      console.log("getWalletBalance API error", e);
      if (e?.response?.status) {
        console.log("getWalletBalance API error", e.response.data);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: catchAPIErrorText(e),
        });
      } else {
        console.log("getWalletBalance API error", {
          ...e,
        });
      }
    } finally {
      setRefreshing(false);
    }
  });

  useEffect(() => {
    getWalletBalance();
  }, []);

  useEffect(
    Platform.OS === "web"
      ? () => {
          const clientSecret = route.params?.payment_intent_client_secret;

          if (!clientSecret) {
            return;
          }
          setCheckoutState((v) => ({
            ...v,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: true,
            transactionId: route.params?.transaction_id,
            customerId: route.params?.cid,
            amount: route.params?.amount,
          }));
        }
      : () => {},
    []
  );

  const topUp = useCallback(async () => {
    if (!amountInputValue || isNaN(amountInputValue)) {
      return;
    }
    setTopupIsLoading(true);
    if (
      checkoutState.clientSecret &&
      +checkoutState.amount === +amountInputValue
    ) {
      if (Platform.OS === "web" || checkoutState.ephemeralKey) {
        if (checkoutState.visible) {
          setCheckoutState((v) => ({
            ...v,
            visible: false,
            isLoading: false,
          }));
        }
        setTimeout(() => {
          setCheckoutState((v) => ({
            ...v,
            visible: true,
            isReloadedPage: true,
            isLoading: true,
          }));
          setTopupIsLoading(false);
        }, 2000);
        return;
      }
    }
    try {
      const useToken = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/wallets/mine/top-up",
        {
          amount: +amountInputValue * 100,
        },
        {
          headers: {
            Authorization: `Bearer ${useToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        const clientSecret =
          res.data?.data?.payment_intent ||
          res.data?.data?.subscription?.latest_invoice?.setup_intent
            ?.client_secret;
        const paymentIntentId = undefined;
        // res.data?.data?.subscription?.latest_invoice?.payment_intent?.id;
        const transactionId = res.data.data?.transaction?.id;
        const ephemeralKey = res.data.data?.ephemeral_key;
        if (res.data?.data?.payment_intent) {
          setCheckoutState((v) => ({
            ...v,
            customerId: res.data?.data?.customer_id,
            paymentIntentId: paymentIntentId,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: false,
            transactionId: transactionId,
            ephemeralKey: ephemeralKey,
            isLoading: true,
            amount: amountInputValue,
          }));
          if (Platform.OS === "web") {
            navigation.setParams({
              payment_intent: paymentIntentId || "",
              payment_intent_client_secret: clientSecret,
              redirect_status: "initiated",
              transaction_id: transactionId,
              amount: amountValue,
            });
          }
        } else {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
                dismissText: undefined,
              });
            },
            bodyText:
              res.data?.message || "Successfully Added Balance to the Wallet",
            dismissText: "Okay",
          });
        }
      }
    } catch (e) {
      console.log("Top Up API error", e);
      if (e?.response?.status) {
        console.log("Top Up API error", e.response.data);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: catchAPIErrorText(e),
        });
      } else {
        console.log("Top Up API error", {
          ...e,
        });
      }
    } finally {
      setTopupIsLoading(false);
    }
  });

  const withdraw = useCallback(async () => {
    if (!amountInputValue || isNaN(amountInputValue)) {
      return;
    }
    setWithdrawIsLoading(true);
    try {
      const useToken = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/wallets/mine/withdraw",
        {
          amount: +amountInputValue * 100,
        },
        {
          headers: {
            Authorization: `Bearer ${useToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        setUserWallet({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUser((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: res.data?.message || "Withdraw request has been sent.",
          dismissText: "Okay",
        });
      }
    } catch (e) {
      console.log("Withdraw API error", e);
      if (e?.response?.status) {
        console.log("Withdraw API error", e.response.data);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
          },
          bodyText: catchAPIErrorText(e),
        });
      } else {
        console.log("Withdraw API error", {
          ...e,
        });
      }
    } finally {
      setWithdrawIsLoading(false);
    }
  });

  const onPaymentSuccess = useCallback(async (data) => {
    try {
      if (Platform.OS === "web") {
        navigation.setParams({
          payment_intent: undefined,
          payment_intent_client_secret: undefined,
          redirect_status: undefined,
          transaction_id: undefined,
          amount: undefined,
        });
      }
      setCheckoutState((v) => ({
        ...v,
        customerId: undefined,
        ephemeralKey: undefined,
        paymentIntentId: undefined,
        clientSecret: undefined,
        transactionId: null,
        visible: false,
        isReloadedPage: false,
        amount: undefined,
      }));
      const token = await isSignedIn();
      let res = await axiosInstance.post(
        `${apiURIV2}/wallets/mine/top-up/${data.transactionId}/confirm`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status === 200) {
        setWalletStatus({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUser((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
        resetAmount();
      }
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
            dismissText: undefined,
          });
        },
        bodyText:
          res.data?.message || "Successfully Added Balance to the Wallet",
        dismissText: "Okay",
      });
    } catch (error) {
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
            dismissText: undefined,
          });
        },
        bodyText: `${error.code}\n${
          error?.message === error?.localizedMessage
            ? error.localizedMessage
            : `${error.message}\t\n\n${error.localizedMessage || ""}`
        }`,
        dismissText: "Okay",
      });
      console.log("Payment confirmation error: ", error);
    }
  }, []);

  const onPaymentError = useCallback((error, paymentIntent) => {
    console.log("onPaymentError:", { ...error }, paymentIntent);
    if (
      (
        error?.payment_intent?.status ||
        paymentIntent?.status ||
        error?.code ||
        ""
      ).toLowerCase() === "canceled"
    ) {
      console.log(
        "cancelled checkoutState: ",
        checkoutState,
        "\ncustom obj: ",
        {
          ...(Platform.OS === "web"
            ? {
                customerId: undefined,
                ephemeralKey: undefined,
                paymentIntentId: undefined,
                clientSecret: undefined,
                transactionId: null,
                amount: undefined,
              }
            : {}),
          visible: false,
          isReloadedPage: false,
        }
      );
      setCheckoutState((v) => ({
        ...v,
        ...(Platform.OS === "web"
          ? {
              customerId: undefined,
              ephemeralKey: undefined,
              paymentIntentId: undefined,
              clientSecret: undefined,
              transactionId: null,
              amount: undefined,
            }
          : {}),
        visible: false,
        isReloadedPage: false,
      }));
    }
    setShowResponseModal({
      visible: true,
      onRequestClose: () => {
        setShowResponseModal({
          visible: false,
          onRequestClose: () => null,
          bodyText: "",
          dismissText: undefined,
        });
      },
      bodyText: `${error.code}\n${
        error?.message === error?.localizedMessage
          ? error.localizedMessage
          : `${error.message}\t\n\n${error.localizedMessage || ""}`
      }`,
      dismissText: "Okay",
    });
  }, []);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getWalletBalance();
  }, [getWalletBalance]);

  return (
    <StripeCheckout
      onSuccess={onPaymentSuccess}
      onError={onPaymentError}
      checkoutData={checkoutState}
      isReloadedPage={checkoutState.isReloadedPage}
      visible={checkoutState.visible}
      onRequestClose={() =>
        setCheckoutState((v) => ({
          ...v,
          visible: false,
        }))
      }
      onCheckoutLoad={() =>
        setCheckoutState((v) => ({ ...v, isLoading: false }))
      }
    >
      <View className="flex-1 bg-app-e lg:bg-gray-50 lg:px-8 lg:pb-4">
        <ScrollView
          className="rounded-t-2xl lg:rounded-none flex-1 bg-gray-50"
          refreshControl={
            Platform.OS === "web" ? null : (
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            )
          }
        >
          <View className="flex-row items-center hidden lg:flex my-2 w-full lg:w-3/12">
            <Font className="text-lg font-semibold text-app-d hidden lg:flex">
              Wallet Balance
            </Font>
          </View>
          <View className="flex-row w-full border-b-2 p-4 border-app-c mb-4">
            <View className="p-4 bg-app-e3 rounded-full mr-4 items-center justify-center border border-app-e">
              <RemixIcon
                name="wallet-fill"
                color={colors.app.e}
                size={30}
              ></RemixIcon>
            </View>
            <View className="flex-col justify-center">
              <Font className="text-app-e text-xl my-1" weight={700}>
                {walletStatus?.currency_symbol
                  ? walletStatus?.currency_symbol
                  : "$"}
                &nbsp;
                {(+(walletStatus?.amount || 0) / 100).toFixed(2)}
              </Font>
              <Font className="text-app-d1 text-xs" weight={500}>
                Collected Wallet Balance
              </Font>
            </View>
          </View>
          <View className="w-full mx-auto flex-1">
            <View className="flex-col h-full max-w-screen w-full relative px-4">
              <Card title={route.name === "withdraw" ? "Withdraw" : "Topup"}>
                <View className="w-full flex-row">
                  <View className="p-4 pt-0 flex-col w-full justify-center items-center">
                    <View className="w-full flex-row lg:w-1/3 pb-4">
                      <Input
                        label={`Amount to ${
                          route.name === "withdraw" ? "Withdraw" : "Topup"
                        }`}
                        leftText="$"
                        type="number"
                        value={amountValue}
                        onChangeText={onChangeAmountText}
                        onBlur={onBlurAmount}
                        note={amountHasError}
                      />
                    </View>
                    <View className="w-full flex-row justify-center">
                      <View className="w-full lg:w-1/3">
                        <Button
                          type="primary"
                          label={
                            route.name === "withdraw" ? "Withdraw" : "Topup"
                          }
                          onPress={route.name === "withdraw" ? withdraw : topUp}
                          waitState={
                            route.name === "withdraw"
                              ? withdrawIsLoading
                              : topupIsLoading || checkoutState.isLoading
                          }
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
        </ScrollView>
      </View>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
    </StripeCheckout>
  );
};

export default TopupWithdrawal;
